import { React, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Textarea,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  Stack,
  Tooltip
} from "@chakra-ui/react";

function TextWithNumberComponent(props) {
  let [value, setValue] = useState(["Call Now", "Visit Now"]);
  const bgColorForInput = useColorModeValue("#e8f0fe", "#e8f0fe");
  const ref = useRef(null);
  const refForMsg = useRef(null);
  const refForValue = useRef(null);
  let handleInputChangeForButtonName = (e) => {
    let inputValue = "";
    // var dataButton = [];

    inputValue = refForValue.current.id;

    const link = document.getElementById(inputValue).value;

    //console.log(link);
    props.getButtonName(link);
  };

  let handleInputChangeButtonValue = (e) => {
    let inputValue = e.target.value;

    // var dataButton = [];

    props.getButtonNameValue(inputValue);
    //console.log(inputValue);
  };
  let handleInputChangeNumberValue = (e) => {
    let inputValue;
    inputValue = refForMsg.current.id;

    const msg = document.getElementById(inputValue).value;

    props.getButtonNameValueMsg(msg);
    //console.log(msg);
  };
  return (
    <>
      <Flex>
        <Stack direction="row" spacing={3}>
          <Text as="b" color="black" mb="8px">
            {props.title}{" "}
          </Text>
          <Tooltip  label='Disabled'>
          <Box>

            <Input
            borderColor="red"
              readOnly={true}
              ref={refForValue}
              id={props.title}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              color="red"
              // value={props.value}
              bg={bgColorForInput}
              variant="auth"
              fontSize="sm"
              ms="2px"
              type="text"
              placeholder={props.placeholderForCall}
              mb="24px"
              size="lg"
              onChange={handleInputChangeForButtonName}
            />
          </Box>
          </Tooltip>
          <Tooltip  label='Disabled'>
          <Box>
            <Input
            borderColor="red"
              readOnly={true}
              ref={ref}
              id={props.placeholder}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              bg={bgColorForInput}
              color="red"
              variant="auth"
              fontSize="md"
              ms="4px"
              type={props.type}
              placeholder={props.placeholder}
              mb="24px"
              size="lg"
              onChange={handleInputChangeButtonValue}
            />
          </Box>
          </Tooltip>
          <Tooltip  label='Disabled'>
          <Box>
            <Input
            borderColor="red"
              readOnly={true}
              ref={refForMsg}
              id={props.placeholderMsg}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              bg={bgColorForInput}
              color="red"
              variant="auth"
              fontSize="md"
              ms="4px"
              type={props.typeMsg}
              placeholder={props.placeholderMsg}
              mb="24px"
              size="lg"
              onChange={handleInputChangeNumberValue}
            />
          </Box></Tooltip>
        </Stack>
      </Flex>
    </>
  );
}

export default TextWithNumberComponent;
