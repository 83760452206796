// import
import React, { Component } from "react";
import Dashboard from "views/Dashboard/Dashboard.js";
import CampaignDashboard from "views/Dashboard/Campaign.js";
import CampainDashboardButton from "views/Dashboard/Campaign_Button.js";
import CampaignDashboardApi from "views/Dashboard/Campaign_btn_api.js";
import Tables from "views/Dashboard/Tables.js";
import LatestCampaign from "views/Dashboard/LatestCampaign.js";
import LatestCampaignReseller from "views/Dashboard/LatestCampaignReseller.js";
import Transaction from "views/Dashboard/transaction.js";
import Billing from "views/Dashboard/Billing.js";
import RTLPage from "views/RTL/RTLPage.js";
import Profile from "views/Dashboard/Profile.js";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";
import Logout from "views/Pages/Logout.js";
//npm install dotenv --save

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/reseller",
  },
  {
    path: "/campaign",
    name: "WAPP",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: CampaignDashboard,
    layout: "/reseller",
  },
  {
    path: "/campaign_btn",
    name: "WBTN",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: CampainDashboardButton,
    layout: "/reseller",
  },
  // {
  //   path: "/campaign_api",
  //   name: "W Api",
  //   rtlName: "",
  //   icon: <StatsIcon color="inherit" />,
  //   component: CampaignDashboardApi,
  //   layout: "/reseller",
  // },
  // {
  //   path: "/tables1",
  //   name: "Report",
  //   rtlName: "",
  //   icon: <StatsIcon color='inherit' />,
  //   component: Tables,
  //   layout: "/reseller",
  // },
  {
    path: "/latestcamp",
    name: "Campaign",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: LatestCampaignReseller,
    layout: "/reseller",
  },
  // {
  //   path: "/tables2",
  //   name: "Campaign Detail reseller",
  //   rtlName: "",
  //   icon: <StatsIcon color='inherit' />,
  //   component: Tables,
  //   layout: "/reseller",
  // },
  {
    path: "/user",
    name: "User",
    rtlName: "",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/reseller",
  },
  {
    path: "/transaction",
    name: "Transaction",
    rtlName: "",
    icon: <StatsIcon color="inherit" />,
    component: Transaction,
    layout: "/reseller",
  },

  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color='inherit' />,
  //   component: RTLPage,
  //   layout: "/rtl",
  // },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/profile",
      //   name: "Profile",
      //   rtlName: "",
      //   icon: <PersonIcon color="inherit" />,
      //   secondaryNavbar: true,
      //   component: Profile,
      //   layout: "/reseller",
      // },
      // {
      //   path: "/signin",
      //   name: "Sign In",
      //   rtlName: "",
      //   icon: <DocumentIcon color='inherit' />,
      //   component: SignIn,
      //   layout: "/auth",
      // },
      // {
      //   path: "/signup",
      //   name: "Create reseller",
      //   rtlName: "",
      //   icon: <RocketIcon color="inherit" />,
      //   component: SignUp,
      //   layout: "/auth",
      // },
      {
        path: "/logout",
        name: "Logout",
        rtlName: "",
        icon: <RocketIcon color="inherit" />,
        component: Logout,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
