import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  AlertDialogCloseButton,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
export default function TransitionExample({
  isOpen,
  onOpen,
  onClose,
  handleClick,
  btnName,
  dialogName,
  dialogmsg,
  btnOkName,
  colorScheme,
}) {
  const cancelRef = useRef();

  return (
    <>
      <Button
        // colorScheme="red"
        p="0px"
        colorScheme={colorScheme}
        variant="solid"
        onClick={onOpen}
      >
        <Flex color="black.500" cursor="pointer" align="center" p="12px">
          <Icon
            as={btnName != "Add Credit" ? FaTrashAlt : FaPencilAlt}
            me="2px"
            fontSize="11px"
          />
          <Text fontSize="12px" fontWeight="semibold">
            {btnName}
          </Text>
        </Flex>
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {dialogName}
            </AlertDialogHeader>

            <AlertDialogBody>{dialogmsg}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                onClick={handleClick}
                colorScheme="red"
                // onClick={onClose}
                ml={3}
              >
                {btnOkName}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
