import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Icon,
  IconButton,
  Spinner,
  Center,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useAlert } from "react-alert";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import fileDownload from "js-file-download";
export default function UpdateModal({
  buttonRef,

  campId,
  reloadHome,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //---------------------------------------------------------------
  //---------------------------------------------------------------

  const [isCompleted, isCompletedSelected] = React.useState(false);
  const [remarks, setValue] = React.useState("");
  const [dropdownvalue, setDropdown] = React.useState("");
  const initialRef = React.useRef(null);
  const progressValue = React.useRef(null);
  const [disabled, setDisabled] = useState(false);
  let loadingStart = false;
  // const finalRef = React.useRef(null)
  let ref = useRef();
  const downloadRef = useRef(null);
  const alert = useAlert();

  let status_value = "";
  const optionsAdmin = [
    { label: "InProgress", value: "inprogress" },
    { label: "Completed", value: "completed" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Failed", value: "failed" },
    { label: "Re-Initiate", value: "reinitiated" },
  ];

  const handleUpdateClick = (remarks, campId, csvData) => {
    var data =
      progressValue.current.value != "reinitiated"
        ? {
            id: campId, ///"cmp41321749", //camp id
            cmp_status: progressValue.current.value, //String
            remarks: remarks != "" ? remarks : "", //'string
            csvData: csvData,
            reinitiated: 0,
          }
        : {
            id: campId, ///"cmp41321749", //camp id
            cmp_status: progressValue.current.value, //String
            remarks: remarks != "" ? remarks : "", //'string
            csvData: csvData,
            reinitiated: 1,
          };
    updateProcess(data);

    // put
    // link :  https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/status_update
    // {
    //   "id": "cmp41321749",//camp id
    //   "cmp_status": "progress"
    // }
  };

  async function updateProcess(data) {
    var updateApi =
      "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/status_update";
    showDialogLoading();
    await axios.put(updateApi, data).then((response) => {
      console.warn(response.data);

      if (response.data["body-json"]["statusCode"] != 200) {
        hideDialogLoading();
        setDisabled(false);
        handleClose();
        // hideLoading();

        alert.error("something went wrong");
      } else {
        // showLoading();
        hideDialogLoading();
        reloadHome(true);
        handleClose();
        alert.success("campaign updated sucessfully");
        // setLoading(true);
      }
      // {handleCallback(true)};
      // loadingStart=true;
    });
  }
  let selectedCSVData;
  const handleCsvFile = (e) => {
    var file = e.target.files[0];
    // console.warn(file.size);
    // //console.logevent.target.files[0]);
    if (
      file === null ||
      file instanceof Blob == false ||
      file.size > 4613734
      //4886579
      // file.size > 2097152 / 4
    ) {
      handleClose();
      alert.show("Select CSV file must be less than 4.5 Mb", {
        title: "Size alert!",
      });
      ref.current.value = "";

      return false;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var fileName = file.name;
      var fileBase64 = reader.result;

      selectedCSVData = { name: fileName, base64: fileBase64 };
    };
    reader.onerror = function (error) {
      //console.log"Error: ", error);
    };
  };
  const handleChange = (e) => {
    //console.log(e);
    if (e != "completed") return isCompletedSelected(false);
    isCompletedSelected(true);
    setDropdown(e);
    status_value = e;
  };
  const handleRemarks = (e) => {
    setValue(e.target.value);
  };
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
  };
  const [loadingDialog, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const showDialogLoading = () => setDialogLoading(true);

  const hideDialogLoading = () => setDialogLoading(false);

  //---------------------------------------------------------------
  async function downloadCSV(csvUrl, filename) {
    await axios({
      url: csvUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename + ".csv");
    });
  }
  //---------------------------------------------------------------
  return (
    <>
      <Button
        style={{ display: "none" }}
        ref={buttonRef}
        variant="primary"
        onClick={handleShow}
      >
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        {dialogLoading ? (
          <Center>
            <Spinner size="xl"></Spinner>
          </Center>
        ) : (
          <Modal.Body>
            <FormControl>
              <FormLabel>Select Progress</FormLabel>
              {/* <Input ref={initialRef} placeholder="First name" /> */}
              <Select
                id={"select"}
                ref={progressValue}
                onChange={(e) => handleChange(e.target.value)}
                placeholder="Select Progress"
              >
                {optionsAdmin.map((key, id) => {
                  return (
                    <option
                      // onChange={(value) => handleChange(value)}
                      key={id}
                      id={id}
                      value={key.value}
                    >
                      {key.label}
                    </option>
                  );
                })}
              </Select>
              <FormLabel> Remarks : </FormLabel>
              <Input placeholder="remarks" onChange={handleRemarks}></Input>
              {isCompleted ? (
                <div>
                  <FormLabel> Sample report CSV : </FormLabel>
                  <IconButton
                    ref={downloadRef}
                    colorScheme="teal"
                    aria-label="CSV Download"
                    size="lg"
                    icon={<DownloadIcon />}
                    // onClick={() => //console.log(cell)}
                    onClick={() =>
                      downloadCSV(
                        "https://whatsappdatastore.s3.ap-south-1.amazonaws.com/demo/DEMOWB",
                        "sample_report"
                      )
                    }
                  />
                </div>
              ) : null}
              {isCompleted ? (
                <>
                  <FormControl isRequired>
                    <FormLabel> Select Report CSV</FormLabel>
                    {/* <Input
                    placeholder="Select CSV"
                    size="md"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleCsvFile}
                  /> */}
                    <input
                      style={mystyle}
                      color="black"
                      ref={ref}
                      type="file"
                      id="pdf"
                      onChange={handleCsvFile}
                      name="input"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                  </FormControl>
                </>
              ) : null}
            </FormControl>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setDisabled(true);
              handleUpdateClick(
                // dropdownvalue,
                remarks,
                campId,
                selectedCSVData != null ? selectedCSVData : {}
              );
            }}
            colorScheme="blue"
            mr={3}
            disabled={disabled}
          >
            update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
