import React from "react";
import { useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import decode from "jwt-decode";
import {
  setAccessToken,
  getAccessToken,
  getJWTData,
} from "loginui/services/variables.js";
import { useAlert } from "react-alert";
// import useAuth from "useAuth";
// Chakra imports
import AlertDialogExample from "../../components/alertDialog/alert";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Spinner,
  Center,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/signInImage.png";
import "components/globalVar/globalvariables";
// import { FALSE } from "sass";
//import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "#1C3FAA");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const navigate = useHistory();
  const alert = useAlert();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  let [isLoggedin, setIsLoggedin] = useState(false);
  const [loading, setLoading] = useState(false);
  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  // <button onClickCapture={logout}>logout user</button>

  // alert.show({
  //   type: "confirm",
  //   title: "Oh look, an alert!",
  //   message: "good",
  //   onSuccess: () => {
  //     //console.log("success");
  //     setTitle("success");
  //   },
  //   onCancel: () => {
  //     //console.log("cancel");
  //     setTitle("cancel");
  //   }
  // });

  // const [tot, setTot] =  useState(); // take tot in a state
  const modal1 = useDisclosure();
  function handleClick() {
    if (username != null && password.length >= 5) {
      callSignInApi();
    } else {
      modal1.onOpen();
      // alert("Username or Password must have 6 digit long");
    }
  }
  function callSignInApi() {
    var jsonLogindata = JSON.stringify({
      username: username,
      password: password,
    });

    try {
      showLoading();

      axios
        .post(
          "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/login",
          jsonLogindata
        )
        .then((response) => {
          console.warn(response);
          if (response.data["body-json"]["statusCode"] != 200) {
            alert.error(response.data["body-json"]["body"], {
              title: "Alert!",
            });
          }

          // "body-json" : {"statusCode":403,"body":"Wrong Password"},
          // console.warn(response.data["body-json"]["body"]["data"]);
          setAccessToken(response.data["body-json"]["body"]); //jwt token is decoded inside variables.js
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data["body-json"]["body"]["data"])
          );
          localStorage.setItem(
            "numberCount",
            JSON.stringify(response.data["body-json"]["body"]["usercount"])
          );
          hideLoading();
          let userData = getJWTData();
          //console.loguserData);
          if (userData["role"] === "admin") {
            //console.log"admin true");
            hideLoading();
            navigate.push("/admin/dashboard");
          } else if (userData["role"] === "subadmin") {
            //console.log"subadmin true");
            hideLoading();
            navigate.push("/subadmin/dashboard");
          } else if (userData["role"] === "reseller") {
            //console.log"reseller true");
            hideLoading();
            navigate.push("/reseller/dashboard");
          } else if (userData["role"] === "user") {
            hideLoading();
            navigate.push("/user/dashboard");
          } else {
            hideLoading();
            navigate.push("/login");
          }
          // navigate.replace("/admin/dashboard");
        })
        .catch((err) => {
          //console.logerr);
          hideLoading();
          // modal1.onOpen();
          // alert(err["message"]);
        });
    } catch (err) {
      // modal1.onOpen();
      // alert(err);
    }
  }

  return (
    <Fragment>
      {loading ? (
        // <View style={styles.spinnerStyle}>
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        <Flex position="relative" mb="40px">
          {/* <AlertDialogExample
            isOpen={modal1.isOpen}
            onClose={modal1.onClose}
            onOpen={modal1.onOpen}
            btnName="Incorrect Details"
            btnOkName="Try Again"
            dialogName="Details"
            colorScheme="teal"
            dialogmsg="Invalid Username or password"
            handleClick={handleModalOk}
          /> */}
          <Flex
            minH={{ md: "600px" }}
            h={{ sm: "initial", md: "45vh", lg: "45vh" }}
            w="100%"
            maxW="1044px"
            mx="auto"
            // justifyContent='space-between'
            mb="30px"
            pt={{ md: "0px" }}
          >
            <Flex
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
              mb="60px"
              mt={{ base: "50px", md: "20px" }}
            >
              <Flex
                zIndex="2"
                direction="column"
                w="445px"
                background="transparent"
                borderRadius="15px"
                p="40px"
                mx={{ base: "100px" }}
                m={{ base: "20px", md: "auto" }}
                bg={bgForm}
                boxShadow={useColorModeValue(
                  "0px 5px 14px rgba(0, 0, 0, 0.05)",
                  "unset"
                )}
              >
                <Text
                  fontSize="xl"
                  color={textColor}
                  fontWeight="bold"
                  textAlign="center"
                  mb="22px"
                >
                  Login
                </Text>

                <FormControl>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Username
                  </FormLabel>
                  <Input
                    id="username"
                    variant="auth"
                    fontSize="sm"
                    ms="4px"
                    type="text"
                    placeholder="Your username"
                    mb="24px"
                    size="lg"
                    onChange={(e) => {
                      setUsername(e.target.value);
                      //console.log"username", e.target.value);
                    }}
                  />
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Password
                  </FormLabel>
                  <Input
                    id="password"
                    variant="auth"
                    fontSize="sm"
                    ms="4px"
                    type="password"
                    placeholder="Your password"
                    mb="24px"
                    size="lg"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      // //console.log"Password : ", e.target.value);
                    }}
                  />
                  <FormControl display="flex" alignItems="center" mb="24px">
                    <Switch id="remember-login" colorScheme="blue" me="10px" />
                    <FormLabel
                      htmlFor="remember-login"
                      mb="0"
                      fontWeight="normal"
                    >
                      Remember me
                    </FormLabel>
                  </FormControl>
                  <Button
                    id="signin"
                    fontSize="10px"
                    variant="dark"
                    fontWeight="bold"
                    w="100%"
                    h="45"
                    mb="24px"
                    onClick={() => handleClick()}
                    // onClick={()=>handleClick()}
                  >
                    {loading ? "CHECKING...." : "SIGN IN"}
                  </Button>
                </FormControl>
                {/* <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColor} fontWeight="medium">
                Don't have an account?
                <Link
                  //to="SignUp"
                  color={titleColor}
                  as="span"
                  ms="5px"
                  href="#"
                  onClick={null}
                  fontWeight="bold"
                >
                  Sign UP
                </Link>
              </Text>
            </Flex> */}
              </Flex>
            </Flex>
            {/* <Box
              overflowX="hidden"
              h="100%"
              w="100%"
              left="0px"
              position="absolute"
              bgImage={signInImage}
            >
              <Box
                w="100%"
                h="100%"
                bgSize="cover"
                bg="blue.500"
                opacity="0.8"
              ></Box>
            </Box> */}
          </Flex>
        </Flex>
      )}
    </Fragment>
  );
}

export default SignIn;
