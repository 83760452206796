import React from "react";
import { useState, useEffect } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { SearchIcon } from "@chakra-ui/icons";
// import { billingData } from "variables/general";
export function Search(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, children, ...rest } = props;
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("#cccccc", "#DFB8B8");
  const inputBg = useColorModeValue("white", "#1C3FAA");
  const bgColorForInput = useColorModeValue("#e8f0fe", "#e8f0fe");
  const [name, setName] = useState("");
  function filter(e) {
    props.result(e);
    setName(e.target.value);
  }

  return (
    <InputGroup
      borderRadius="8px"
      borderColor="2px solid gray"
      w="500px"
      {...rest}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="#008081"
            borderRadius="black"
            borderLeftRadius="5px"
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          ></IconButton>
        }
      />
      <Input
        // bg={bgColorForInput}
        // variant="search"
        // fontSize="lg"
        // color="black"
        // // bg={inputBg}
        // value={name}
        // type="search"
        // fontWeight="bold"
        // placeholder="Type here..."
        // onChange={filter}
        bg="#f3f3f3"
        border="1px solid gray"
        variant="search"
        fontSize="lg"
        color="black"
        // bg={inputBg}
        value={name}
        type="search"
        fontWeight="bold"
        placeholder="  Search By Name, Username, Email"
        _placeholder={{ opacity: 0.4, color: "inherit" }}
        onChange={(e) => filter(e)}
      />
    </InputGroup>
  );
}
export default Search;
