import { React, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
} from "@chakra-ui/react";

import "../../components/UserDiv/userdiv.css";
import { useAlert } from "react-alert";
import FileBase64 from "react-file-base64";
function VideoComponent(props) {
  const [setVideo, setVideoBase64] = useState("");
  const alert = useAlert();
  let ref = useRef();
  const onChange = (event) => {
    // data for submit
    var file = event.target.files[0];
    // //console.logevent.target.files[0]);
    if (
      file === null ||
      file instanceof Blob == false ||
      file.size > 3145728
      // file.size > 2097152
    ) {
      alert.show("Please attach video of size less than 3 MB", {
        title: "Video Size",
      });
      ref.current.value = null;
      return false;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var fileName = file.name;
      var fileBase64 = reader.result;
      var mapData = { name: fileName, base64: fileBase64 };
      // console.warn(mapData);
      props.videoBase64(mapData);
      // props.pdfdata(file);

      // //console.logreader.result);
      // //console.logfileName);
      // alert('uploading data : ' + jsondata)
    };
    reader.onerror = function (error) {
      //console.log"Error: ", error);
    };
  };

  const removeSelected = () => {
    ref.current.value = null;
  };
  const mystyle = {
    color: "white",
    backgroundColor: "green",
    padding: "10px",
    fontFamily: "Arial",
  };
  return (
    <div style={{ maxWidth: "14rem" }}>
      <Box height="40px">
        <Text
          fontWeight="bold"
          pt="0px"
          pl="5px"
          ml="-14px"
          mr="-14px"
          mb="14px"
          mt="-14px"
          borderBottom="2px green solid"
          backgroundColor="lightgreen"
        >
          Upload Video:
        </Text>
      </Box>
      <Text as="b" color="black" fontSize="lg" fontWeight="bold">
        Select the Video:
      </Text>

      <Flex direction="column" pt={{ md: "25px" }}>
        {/* <FileBase64
        type="application/pdf"
        multiple={ false }
        onDone={ getFiles.bind(this) } /> */}
        <input
          disabled={props.isPreview}
          // readOnly={props.preview}
          className="choose-video"
          ref={ref}
          type="file"
          id="video"
          name="input"
          onChange={onChange}
          accept="video/mp4, video/mov,application/mp4  "
        />
        <br />
        <Button onClick={removeSelected}>remove video</Button>
        <br />
      </Flex>
    </div>
  );
}

export default VideoComponent;
