import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import { PropTypes } from "prop-types";
import {
  getUserData,
  getJWTData,
  // getUserRole,
} from "../../loginui/services/variables";
// import { getUserData, getJWTData,getUserRole } from "../loginui/services/variables";
import React from "react";
import axios from "axios";
import { useAlert } from "react-alert";
export default function PreviewBox({
  isOpen,
  onOpen,
  onClose,
  handleReviewSubmit,
  numberCount,
  image,
  pdfName,

  videoName,

  message,
}) {
  // PreviewBox.propTypes={
  //   numberCount: PropTypes.number,
  //   image:PropTypes.String,
  //   pdfName:PropTypes.String,

  //             videoSelected:PropTypes.String,

  //             message:PropTypes.String
  // }

  // let userRole = getUserRole();
  // console.warn(userRole);
  const initialRef = React.useRef(null);
  const alert = useAlert();

  //---------------------

  // create account function

  async function signup(
    name,
    username,
    email,
    phone,
    role,
    password,
    validity
  ) {
    var userData = JSON.parse(localStorage.getItem("userData"));
    // showLoading();
    var userData = JSON.stringify({
      name: name,
      username: username,
      phone: phone,
      role: role,
      company: "non",
      password: password,
      email: email,
      refund_to_parent: null,
      parent_username: userData["username"],
      creater_role: userData["role"],
    });

    await axios
      .post(
        `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/register`,
        userData
      )
      .then((response) => {
        if (response.data["body-json"]["statusCode"] != 200) {
          alert.error(response.data["body-json"]["body"], {
            title: "Alert!",
          });
        }
        //console.log(response.data["body-json"]["body"]["message"]);
        alert.success(response.data["body-json"]["body"]["message"], {
          title: "Success!",
        });
        //  alert.success(
        //    "result :",
        //    response.data["body-json"]["body"]["message"]
        //  );
        // tFoundUsers(...foundUsers, response.data["body-json"]["body"]);
      })
      .then((data) => {
        onClose();
        //  hideLoading();
        //  hidePopup();
      });
  }

  //-----------------------------

  return (
    <>
      <Button
        size="lg"
        colorScheme="teal"
        variant="solid"
        pl="100px"
        pr="100px"
        onClick={onOpen}
      >
        Preview
      </Button>

      <Modal leastDestructiveRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview Box</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl onSubmit={() => null}>
              <FormLabel>Number Count : </FormLabel>
              {/* <Text>{numberCount}</Text> */}
              <Input
                readOnly={true}
                value={numberCount}
                id="number"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Message : </FormLabel>
              {/* <Text>{message}</Text> */}
              {/* <Input
                readOnly={true}
                value={message}
                id="number"
                // onChange={handleChange}
                // placeholder="Email"
              /> */}
              <Textarea
                maxHeight="80px"
                readOnly={true}
                value={message}
                id="message"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Image(s) Count : </FormLabel>
              <Input
                readOnly={true}
                value={image}
                id="image"
                // onChange={handleChange}
                // placeholder="Email"
              />
              {/* { image.map((e)=>{

              <Text>{e.name}</Text>
              })} */}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Video Name : </FormLabel>
              {/* <Text>{videoName}</Text> */}
              <Input
                readOnly={true}
                value={videoName}
                id="video"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Pdf Name : </FormLabel>
              {/* <Text>{pdfName}</Text> */}
              <Input
                readOnly={true}
                value={pdfName}
                id="pdf"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              onClick={() => handleReviewSubmit()}
              colorScheme="blue"
              mr={3}
            >
              Upload
            </Button>
            <Button ref={initialRef} onClick={onClose}>
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
