import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Select,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { PropTypes } from "prop-types";
import {
  getUserData,
  getJWTData,
  // getUserRole,
} from "../../loginui/services/variables";
// import { getUserData, getJWTData,getUserRole } from "../loginui/services/variables";
import React from "react";
import axios from "axios";
import { useAlert } from "react-alert";
export default function PreviewBoxBtn({
  isOpen,
  onOpen,
  onClose,
  handleReviewSubmit,
  numberCount,
  image,
  pdfName,
  btnNameList,
  btnValueList,
  wamecaption,
  wamemsg,
  wamename,
  videoName,

  message,
}) {
  // //console.log(btnNameList, btnValueList);

  // let userRole = getUserRole();
  // console.warn(userRole);
  const initialRef = React.useRef(null);
  const alert = useAlert();

  //---------------------

  //-----------------------------

  return (
    <>
      <Button
        size="lg"
        colorScheme="teal"
        variant="solid"
        pl="100px"
        pr="100px"
        onClick={onOpen}
      >
        Preview
      </Button>

      <Modal leastDestructiveRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview Box</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl onSubmit={() => null}>
              <FormLabel>Number Count : </FormLabel>
              {/* <Text>{numberCount}</Text> */}
              <Input
                readOnly={true}
                value={numberCount}
                id="number"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Message : </FormLabel>
              {/* <Text>{message}</Text> */}
              {/* <Input
                readOnly={true}
                value={message}
                id="message"
                // onChange={handleChange}
                // placeholder="Email"
              /> */}
              <Textarea
                maxHeight="80px"
                readOnly={true}
                value={message}
                id="message"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Image(s) Count : </FormLabel>
              <Input
                readOnly={true}
                value={image}
                id="image"
                // onChange={handleChange}
                // placeholder="Email"
              />
              {/* { image.map((e)=>{

              <Text>{e.name}</Text>
              })} */}
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Call Caption &amp; Number:</FormLabel>
              {
                <Flex direction="row">
                  <Input
                    readOnly={true}
                    value={
                      btnNameList.length != []
                        ? btnNameList.buttonNameaFromChild[0]
                        : ""
                    }
                    id="callcaption"
                  />
                  <Input
                    readOnly={true}
                    value={
                      btnValueList.length != []
                        ? btnValueList.buttonDataFromChild[0]
                        : ""
                    }
                    id="callnum"
                  />
                </Flex>
              }
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Link Caption &amp; Number:</FormLabel>
              {
                <Flex direction="row">
                  <Input
                    readOnly={true}
                    value={
                      btnNameList.length != []
                        ? btnNameList.buttonNameaFromChild[1]
                        : ""
                    }
                    id="link"
                  />
                  <Input
                    readOnly={true}
                    value={
                      btnValueList.length != []
                        ? btnValueList.buttonDataFromChild[1]
                        : ""
                    }
                    id="linkcaption"
                  />
                </Flex>
              }
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>WA Me Caption, Number &amp; Msg:</FormLabel>
              <Flex direction="row">
                <Input readOnly={true} value={wamename} id="wamename" />
                <Input readOnly={true} value={wamecaption} id="wamecaption" />
                <Input readOnly={true} value={wamemsg} id="wamemsg" />
              </Flex>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Video Name : </FormLabel>
              {/* <Text>{videoName}</Text> */}
              <Input
                readOnly={true}
                value={videoName}
                id="video"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Pdf Name : </FormLabel>
              {/* <Text>{pdfName}</Text> */}
              <Input
                readOnly={true}
                value={pdfName}
                id="pdf"
                // onChange={handleChange}
                // placeholder="Email"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              onClick={() => handleReviewSubmit()}
              colorScheme="blue"
              mr={3}
            >
              Upload
            </Button>
            <Button ref={initialRef} onClick={onClose}>
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
