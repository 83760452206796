import { DownloadIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { FaCloudDownloadAlt } from "react-icons/fa";
import DropDown from "components/dropdown";
import UpdateProcess from "components/alertDialog/updateProcess";
function TablesTableRow(props) {
  const {
    logo,
    name,
    email,
    subdomain,
    domain,
    status,
    date,
    time,
    isLast,
    csvUrl,
  } = props;
  const textColor = useColorModeValue("black", "black");
  const titleColor = useColorModeValue("gray.700", "black");
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  // FaCloudDownloadAlt
  const ref = useRef(null);
  const modal1 = useDisclosure();

  // handleCallback = (e)=>{
  //   console.warn(e);
  // }
  async function downloadCSV(csvUrl, filename) {
    await axios({
      url: csvUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename + ".csv");
    });
  }
  return (
    <Tr borderBottom="2px solid silver">
      <Td
        minWidth={{ sm: "250px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : null}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Avatar src={logo} w="50px" borderRadius="12px" me="18px" />
          <Flex direction="column">
            <Text
              fontSize="md"
              color={titleColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {name}
            </Text>
            <Text fontSize="sm" color="black" fontWeight="normal">
              {email}
            </Text>
          </Flex>
        </Flex>
      </Td>

      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {domain}
          </Text>
          <Text fontSize="sm" color="black" fontWeight="normal">
            {subdomain}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Flex direction="row">
          <Badge
            bg={status === "Inprogress" ? "green.400" : bgStatus}
            color={status === "Inprogress" ? "white" : "white"}
            fontSize="16px"
            p="3px 10px"
            borderRadius="8px"
          >
            {status}
          </Badge>
          {/* <UpdateProcess
            isOpen={modal1.isOpen}
            onClose={modal1.onClose}
            onOpen={modal1.onOpen}
            campId={domain}

            // handleCallBack={handleCallback}
          ></UpdateProcess> */}
        </Flex>
        {/* <DropDown></DropDown> */}
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {time}
        </Text>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
        <IconButton
          ref={ref}
          colorScheme="teal"
          aria-label="CSV Download"
          size="lg"
          icon={<DownloadIcon />}
          onClick={() => downloadCSV(csvUrl, domain)}
        />
        {/* <Button p="0px" bg="transparent" variant="no-effects">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button> */}
      </Td>
    </Tr>
  );
}

export default TablesTableRow;
