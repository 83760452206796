// 1. Import the utilities
import { createBreakpoints } from "@chakra-ui/theme-tools";
// 2. Update the breakpoints as key-value pairs
export const breakpoints = createBreakpoints({
  xsm: "",
  sm: "310px",
  md: "320px",
  lg: "1024px",
  xl: "1140px",
  "2xl": "1680px",
});
