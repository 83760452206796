// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Spinner,
  Center,
  Button,
  Box,
} from "@chakra-ui/react";
// Custom components
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, usePagination } from "react-table";
import "./index.css";
import { useSortableTable } from "./useSortableTable";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TransTablesTableRow from "components/Tables/TransTablesTableRow";
import React, { useState, useEffect, Fragment, useMemo } from "react";
import { tablesProjectData, tablesTableData } from "variables/general";
import { getUserData, getJWTData } from "loginui/services/variables";
import SearchBar from "components/search/search.js";
import axios from "axios";
import Pagination from "../Pagination/Pagination";
import "../../components/UserDiv/userdiv.css";
let PageSize = 5;
function Tables() {
  const textColor = useColorModeValue("black", "black");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const apiUrl =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/admin_translist";
  const apiUrlUser =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/trans_list";
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [APIData, setAPIData] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortType, setSortType] = useState("desc");
  function result(event) {
    //capturing search letter from user
    // //console.logevent.target.value);
    // filter(event);
    setSearchText(event);
  }
  const toggleSort = () => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
  };
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return foundUsers.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage, foundUsers]);

  //---------------------------------------------
  useEffect(() => {
    var auth = getUserData();

    if (auth) {
      getTransactionList();
    } else {
      history.push("/login");
    }
  }, [setAPIData]);
  async function getTransactionList() {
    var userData = getJWTData();
    var username = userData["username"];
    var role = userData["role"];
    var dataToUpload = JSON.stringify({
      transaction_user: username,
    });
    var dataToUploadForUser = JSON.stringify({
      username: username,
    });
    switch (role) {
      case "admin":
        //console.log(role);
        showLoading();
        await axios
          .post(apiUrl, dataToUpload)
          .then((response) => {
           
            
            // //console.log(response);

            var data =
              response.data["body-json"]["body"]["transaction"]["Items"];

            setFoundUsers(data);
            setAPIData(...APIData, data);
          })
          .then((data) => {
            hideLoading();
          });
        break;
      case "subadmin":
        // //console.log(role);
        showLoading();
        await axios
          .post(apiUrlUser, dataToUpload)
          .then((response) => {
            var data = response.data["body-json"]["body"]["Items"];

            setFoundUsers(data);
            setAPIData(...APIData, data);
          })
          .then((data) => {
            hideLoading();
          });
        break;
      case "reseller":
        // //console.log(role);
        showLoading();
        await axios
          .post(apiUrlUser, dataToUpload)
          .then((response) => {
            //console.log(response.data);
            var data = response.data["body-json"]["body"]["Items"];

            setFoundUsers(data);
            setAPIData(...APIData, data);
          })
          .then((data) => {
            hideLoading();
          });
        break;
      case "user":
        // //console.log(role);
        showLoading();
        await axios
          .post(apiUrlUser, dataToUpload)
          .then((response) => {
            console.warn(response.data);
            var data = response.data["body-json"]["body"]["Items"].sort(
              (a, b) => new Date(b.date) - new Date(a.date)
            );

            setFoundUsers(data);
            setAPIData(...APIData, data);
          })
          .then((data) => {
            hideLoading();
          });
        break;

      default:
        break;
    }
  }

  useEffect(() => {}, [foundUsers]);
  function result(event) {
    if (event.length >= 0) {
      // console.warn(event.length);
      searchUser(event);
    } else {
      getTransactionList();
    }
  }

  const [loadingsearch, setLoadingsearch] = useState(false);
  const showSearchLoading = () => setLoadingsearch(true);

  const hideSearchLoading = () => setLoadingsearch(false);
  async function searchUser(query) {
    var userData = getJWTData();
    var username = userData["username"];
    showSearchLoading();
    await axios
      .get(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/trans_search?key=" +
          query +
          "&key1=" +
          username
      )
      .then((res) => {
        if (foundUsers.length >= 0) {
          // console.warn(res.data);
          console.warn(res.data["body"]["Items"]);
          var data = res.data["body"]["Items"];

          setFoundUsers(data);
          // setFoundUsers(res.data["body"]["message"]);
          hideSearchLoading();
          //console.logres.data["message"]);
        }
      });
  }

  const columns = useMemo(
    () => [
      { Header: "User Id", accessor: "transaction_user", sortable: true },
      { Header: "Transaction Id  ", accessor: "transid", sortable: true },
      { Header: "Credit", accessor: "credit", sortable: true },
      { Header: "Debit  ", accessor: "debit", sortable: true },
      {
        Header: "Date",
        accessor: "date",
        sortable: true,
        // sortbyOrder: "desc",
       
        // sortOrder:"desc",
        // sortType: (a, b) => {
        
        //   return new Date(b.values.date) - new Date(a.values.date);
        // },
      },
      { Header: "Time", accessor: "time", sortable: true, sortbyOrder: "desc", },
      { Header: "Remarks", accessor: "remark", sortable: false },
    ],
    []
  );
  const sortees = React.useMemo(
    () => [
      {
        id: "date",
        desc: false
      },
      {
        id: "time",
        desc: true
      }
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data: foundUsers,
      initialState:{
        sortBy:sortees,
      }
    },
    useSortBy,
    usePagination
  );
  const [tableData, handleSorting] = useSortableTable(foundUsers, columns);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSortingChange = (accessor) => {
    //console.log(accessor);
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
    //  //console.log(tableData);
  };
  return (
    <Fragment>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        // color="#C5CAE2"
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          {/* Search Card */}
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
            <Flex
              style={{ gap: "13px" }}
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              maxWidth="70%"
              sx={{ display: "flex", justifyContent: "space-between" }}
              p={{ md: "20px" }}
            >
              <SearchBar result={(e) => result(e.target.value)}></SearchBar>
              {/* <Button onClick={toggleSort}>
                Sort By <span>'Date'</span>
                {sortType}
              </Button> */}
            </Flex>
          </Card>

          <Card
            bg="#f3f3f3"
            overflowX={{ sm: "scroll", xl: "hidden" }}
            pb="0px"
            marginTop="10px"
          >
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Transaction Table
              </Text>
            </CardHeader>
            {loadingsearch ? (
              <Center>
                <Spinner size="xl"></Spinner>
              </Center>
            ) : (
              <CardBody>
                <Table className="table table-bordered" {...getTableProps()}>
                  <Thead>
                    {headerGroups.map((headerGroup) => (
                      
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <Th
                            userSelect="none"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <Flex alignItems="center">
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ChevronDownIcon ml={1} w={4} h={4} />
                                ) : (
                                  <ChevronUpIcon ml={1} w={4} h={4} />
                                )
                              ) : (
                                ""
                              )}
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <Td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </CardBody>
            )}
          </Card>
        
        </Flex>
      )}
      <>
        <Box marginTop="10px">
          <Center>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </Center>
        </Box>
        <br />
      <div>Showing the first 10 results of {rows.length} </div>
      </>
   
    </Fragment>
  );
}

export default Tables;

