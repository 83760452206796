import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  FormControl,
  FormLabel,
  Input,
  Flex,
  Box,
  Select,
  Icon,
  IconButton,
  Spinner,
  Center,
  Text,
  Image,
  FormErrorMessage,
  FormHelperText,
  Textarea,
} from "@chakra-ui/react";
import fileDownload from "js-file-download";
import { EditIcon } from "@chakra-ui/icons";
import { useAlert } from "react-alert";
import axios from "axios";
import { DownloadIcon } from "@chakra-ui/icons";
import utf8 from "utf8";
export default function DetailsModal({ buttonRef, tableData }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //---------------------------------------------------------------

  // const decoded = utf8.decode(encoded);

  //---------------------------------------------------------------

  const [isCompleted, isCompletedSelected] = React.useState(false);
  const [remarks, setValue] = React.useState("");
  const [dropdownvalue, setDropdown] = React.useState("");
  const initialRef = React.useRef(null);
  const progressValue = React.useRef(null);
  const [disabled, setDisabled] = useState(false);
  let loadingStart = false;
  // const finalRef = React.useRef(null)
  let ref = useRef();
  const alert = useAlert();

  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
  };
  const [loadingDialog, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const showDialogLoading = () => setDialogLoading(true);

  const hideDialogLoading = () => setDialogLoading(false);

  //---------------------------------------------------------------

  async function downloadCSV(csvUrl, filename) {
    var splittedArray = csvUrl.split(".");
    // var data = splittedArray.split(".");
    await axios({
      url: csvUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename + "." + splittedArray[5]);
      // fileDownload(res.data, filename + ".csv");
    });
  }

  //---------------------------------------------------------------
  return (
    <>
      <Button
        style={{ display: "none" }}
        ref={buttonRef}
        variant="primary"
        onClick={handleShow}
      >
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Campaign Details</Modal.Title>
        </Modal.Header>
        {dialogLoading ? (
          <Center>
            <Spinner size="xl"></Spinner>
          </Center>
        ) : (
          <Modal.Body>
            <FormControl mt={4}>
              <FormLabel>WAPP Type : </FormLabel>
              <Input readOnly={true} value={tableData.type} id="type" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Image List : </FormLabel>
              {/* <FormLabel>{tableData.image_url}</FormLabel> */}

              {tableData.image_url.length > 0 ? (
                // <Image
                //   height={"75px"}
                //   width={"75px"}
                //   readOnly={true}
                //   src={
                //     "https://tis-whatsapp-b.s3.ap-south-1.amazonaws.com/images/he_govind_317406038_203559785466542_2592469749480013_n.jpg"
                //   }
                //   id={"element"}
                // />
                tableData.image_url.map((element) => {
                  return (
                  
                    <Flex >
                      <Box>
                        <Image
                          height={"75px"}
                          width={"75px"}
                          readOnly={true}
                          src={element}
                          id={element}
                          key={element}
                        />
                      </Box>
                    </Flex>
                   
                  );
                })
              ) : (
                // tableData.image_url.map((element) => {
                //   return (
                //     <Flex direction={"row"}>
                //       <Image
                //         height={"75px"}
                //         width={"75px"}
                //         readOnly={true}
                //         src={element}
                //         id={element}
                //         key={element}
                //       />
                //     </Flex>
                //   );
                // })
                <Text>No Image(s) uploaded</Text>
              )}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Video Url : </FormLabel>

              {tableData.video_url != "" ? (
                <IconButton
                  ref={ref}
                  colorScheme="teal"
                  aria-label="Video Download"
                  size="lg"
                  icon={<DownloadIcon />}
                  // onClick={() => //console.log(cell)}
                  onClick={() => downloadCSV(tableData.video_url, tableData.id)}
                />
              ) : (
                <Text>No uploaded Video</Text>
              )}
              {/* <Image
                  readOnly={true}
                  value={element}
                  id={element}
                /> */}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Pdf Url : </FormLabel>
              {tableData.pdf_url != "" ? (
                <IconButton
                  ref={ref}
                  colorScheme="teal"
                  aria-label="Pdf Download"
                  size="lg"
                  icon={<DownloadIcon />}
                  // onClick={() => //console.log(cell)}
                  onClick={() => downloadCSV(tableData.pdf_url, tableData.id)}
                />
              ) : (
                <Text>No uploaded Video</Text>
              )}
              {/* <Image
                  readOnly={true}
                  value={element}
                  id={element}
                /> */}
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Message : </FormLabel>
              {tableData.message != "" ? (
                <Textarea
                  maxHeight="80px"
                  readOnly={true}
                  // value={tableData.message}
                  value={utf8.decode(tableData.message)}
                  id="message"
                />
              ) : (
                <Text>No uploaded Message</Text>
              )}
            </FormControl>

            {/* <Text> {tableData.type}</Text>
            <Text>{tableData.csv}</Text>
            <Text>{tableData.csv}</Text>
            <Text>{tableData.video_url}</Text>
            <Text>{tableData.csv}</Text>
            <Text>{tableData.csv}</Text>
            <Text>{tableData.csv}</Text> */}
          </Modal.Body>
        )}

        {/*

{
    "persion_name": "test ",
    "csv": "https://whatsappdatastore.s3.ap-south-1.amazonaws.com/csv/cmpWB66471300.csv",
    "time": "1:25:34 am",
    "pdf_url": "https://tis-whatsapp-b.s3.ap-south-1.amazonaws.com/pdf/img9.pdf",
    "video_url": "no video to upload",
    "name": "CMPcIzBOOOu",
    "count": "1",
    "role": "user",
    "username": "test11",
    "cmp_status": "pending",
    "reinitiated": 0,
    "creationdate": "21/3/2023",
    "image_url": [
        "https://tis-whatsapp-b.s3.ap-south-1.amazonaws.com/images/he_govind_317406038_203559785466542_2592469749480013_n.jpg",
        "https://tis-whatsapp-b.s3.ap-south-1.amazonaws.com/images/he_govind_317406038_203559785466542_2592469749480013_n.jpg"
    ],
    "id": "cmpWB66471300",
    "remarks": "",
    "type": "whatsapp_balance"
}

*/}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button
            variant="primary"
            
            colorScheme="blue"
            mr={3}
            disabled={disabled}
          >
            update
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
