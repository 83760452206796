import React, { useEffect, useState, useRef } from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserData } from "./loginui/services/variables";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  // const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
  // let auth = false;
  // const [auth,setAuth] = useState(false);

  const auth = useRef(false);

  useEffect(() => {
    auth.current = getUserData();
    // console.log(auth.current);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth) return <Component {...props} />;
        if (!auth)
          return (
            <Redirect
              to={{ path: "/login", state: { from: props.location } }}
            />
          );
      }}
    />
  );
};

export default ProtectedRoute;
