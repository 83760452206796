import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  IconButton,
  Spinner,
  Center,
  FormErrorMessage,
  VStack,
} from "@chakra-ui/react";

import React from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { Formik, Field } from "formik";
import { CalendarIcon } from "@chakra-ui/icons";
//CalendarIcon
export default function Validity({ isOpen, onOpen, onClose, userid }) {
  // let userRole = getUserRole();
  // console.warn(userRole);
  const initialRef = React.useRef(null);
  const alert = useAlert();
  const [showBalance, setShowBalance] = React.useState(false);
  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState("");
  const [data, setData] = React.useState({
    username: "",
    password: "",
  });

  // const handleChange = (event) => {
  //   setData({ ...data, [event.target.id]: event.target.value });
  //   console.warn(data);
  // };

  const handleClose = () => {
    setShowBalance(false);
    onClose();
  };

  //---------------------

  // create account function

  async function changeUserPassword(username, validity) {
    // var userData = JSON.parse(localStorage.getItem("userData"));
    showLoading();
    var userData = JSON.stringify({
      username: username,
      validity: validity,
    });

    console.warn(userData);
    setShowBalance(true);
    await axios
      .post(
        `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/account_control `,
        userData
        // { data: userData }
      )
      .then((response) => {
        if (response.data["body-json"]["statusCode"] != 200) {
          alert.error("Something went wrong! try again", {
            // title: "Alert!",
          });
          setShowBalance(false);
          hideLoading();
          onClose();
        } else {
          hideLoading();
          onClose();
          alert.success("Validity Changed");
        }
      });
  }

  //-----------------------------

  return (
    <>
      <IconButton icon={<CalendarIcon />} onClick={onOpen}>
        Change Validity
      </IconButton>

      <Modal leastDestructiveRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Validity</ModalHeader>
          <ModalCloseButton />
          {loading ? (
            // <View style={styles.spinnerStyle}>
            <Center>
              <Spinner
                // style={{
                //   // flex: 1,
                //   marginTop: 100,
                //   marginLeft: 100,
                //   justifyContent: "center",
                //   alignItems: "center",
                //   // alignSelf: "center",
                // }}
                size="xl"
              ></Spinner>
            </Center>
          ) : (
            <ModalBody pb={6}>
              <Formik
                initialValues={{
                  username: userid,

                  password: "",
                }}
                onSubmit={(values) => {
                  changeUserPassword(
                    values.username,

                    values.validity
                  );
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                      <FormControl
                        isRequired
                        //  isInvalid={!!errors.username && touched.username}
                      >
                        <FormLabel>Username</FormLabel>
                        <Input
                          readOnly={true}
                          value={userid}
                          required={true}
                          id="username"
                          // onChange={handleChange}
                          placeholder="Username"
                        />
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.validity && touched.validity}
                      >
                        <FormLabel>New Validity Date"</FormLabel>
                        <Field
                          as={Input}
                          id="validity"
                          name="validity"
                          type="date"
                          placeholder="New Validity Date"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "Error";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.validity}</FormErrorMessage>
                      </FormControl>
                      <Button
                        disabled={showBalance}
                        type="submit"
                        // onClick={() => {
                        //   changeUserPassword(userid);
                        // }}
                        colorScheme="blue"
                        mr={3}
                      >
                        update
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </ModalBody>
          )}
          <ModalFooter>
            <Button ref={initialRef} onClick={handleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
