import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, useReducer } from "react";
import { getJWTData } from "loginui/services/variables";
import axios from "axios";
function BalanceComponent(props) {
  const [balance, setUserBalance] = useState([
    { api_balance: 0, whatsapp_balance: 0, whatsapp_button_balance: 0 },
  ]);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // const fetchBalance = async () => {
    var username = getJWTData();
    // //console.logusername["username"]);
    var dataUsername = JSON.stringify({ username: username["username"] });
    axios
      .post(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/credit_user",
        dataUsername
      )
      .then((response) => {
        const newUserBalance =
          response["data"]["body-json"]["body"]["Items"][0];

        setUserBalance(response["data"]["body-json"]["body"]["Items"][0]);
        //console.log("called balance function");
        // fetchBalance();
        // setInterval(() => {
        //   forceUpdate();
        // }, 10000);

        // //console.logresponse["data"]["body-json"]["body"]["Items"][0]);
      });
    // };
  }, []);

  // credit user
  // GET
  // link :  https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/credit_user
  // {
  //   "username":"sagar124"
  // }
  // Chakra Color Mode

  // useEffect(() => {
  //   const fetchBalance = async () => {
  //     var username = getJWTData();
  //     // //console.logusername["username"]);
  //     var dataUsername = JSON.stringify({ username: username["username"] });
  //     axios
  //       .post(
  //         "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/credit_user",
  //         dataUsername
  //       )
  //       .then((response) => {
  //         const newUserBalance =
  //           response["data"]["body-json"]["body"]["Items"][0];

  //         setUserBalance((prev) => ({ ...prev, newUserBalance }));
  //         //console.log"balance update");
  //         // forceUpdate();
  //         // //console.logresponse["data"]["body-json"]["body"]["Items"][0]);
  //       });
  //     // fetchBalance();
  //     // forceUpdate();
  //   };
  // }, []);

  return (
    <>
      <Stack direction="row" spacing={3} marginBottom="40px" p="10px">
        <Box>
          {" "}
          <Text color="black" as="b">
            WB: {balance.whatsapp_balance}
          </Text>{" "}
        </Box>
        <Spacer></Spacer>
        <Spacer></Spacer>
        <Box>
          {" "}
          <Text color="black" as="b">
            WBB: {balance.whatsapp_button_balance}
          </Text>{" "}
        </Box>
        <Spacer></Spacer>
        <Spacer></Spacer>
        <Box>
          {" "}
          <Text color="black" as="b">
            WAPI: {balance.api_balance}
          </Text>{" "}
        </Box>
        {/* <Box> <Text color="black" as="b"></Text> </Box> */}
        <Spacer></Spacer>
        <Spacer></Spacer>
      </Stack>
    </>
  );
}
export default React.memo(BalanceComponent);
