import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  GridItem,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import encodeUtf8 from "encode-utf8";
import { getUserData } from "loginui/services/variables";
import axios from "axios";
import BalanceComponent from "components/Balance";
// import { React, useState } from "react";
import {
  createContext,
  useState,
  useCallback,
  Fragment,
  useEffect,
  useContext,
} from "react";
import { Spinner } from "@chakra-ui/spinner";
import NumberComponent from "components/campains/number.js";
import MessageComponent from "components/campains/message.js";
import ImageComponent, { ImageData } from "components/campains/image.js";
import VideoComponent from "components/campains/videoupload.js";
import PdfComponent from "components/campains/pdfupload.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import utf8 from "utf8";
import Alert from "components/alertDialog/alert";
import { useHistory } from "react-router-dom";
import { getJWTData } from "loginui/services/variables";
import PreviewBox from "./PreviewBox";
export const GlobalInfoCamp = createContext();
import { useAlert } from "react-alert";
function CampainDashboard(props) {
  const alert = useAlert();
  const textColor = useColorModeValue("gray.700", "white");
  // const bgColor = useColorModeValue("#F8F9FA", "navy.700");

  const bgColor = useColorModeValue("#e8f0fe", "#e8f0fe");
  const nameColor = useColorModeValue("gray.500", "white");
  const { name, company, email, userid } = props;
  const modal1 = useDisclosure();
  const [error, setError] = useState("");
  let startUploading = false;
  let pdfDataFromChild;
  let videoDataFromChild;
  let imageDataFromChild;
  let numberDataFromChild;
  let messageDataFromChild = "";
  let stringArray;
  let pdfSelected = false;
  let videoSelected = false;
  let imageSelected = false;
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [preview, setPreview] = useState(false);
  const showPreview = () => setPreview(true);

  const hidePreview = () => setPreview(false);
  const [numberArray, setNumberArray] = useState([]);
  const [numberValidated, setNumberValidated] = useState(false);
  const [imageList, setImageList] = useState([]);
  const imageData = useContext(ImageData);
  const [video, setVideo] = useState({});
  const [pdf, setPdf] = useState({});
  const [encodedMessage, setEncodedMessage] = useState("");
  const [selecteddata, setSelecteddata] = useState({
    message: "",
    pdfname: "",
    videoname: "",
    imageList: [],
  });
  useEffect(() => {
    var auth = getUserData();
    if (auth) {
    } else {
      history.push("/login");
    }
  }, []);

  const setBase64 = (videoData) => {
    console.warn(videoData);
    videoDataFromChild = videoData;
    videoSelected = true;
    setSelecteddata({
      ...selecteddata,
      videoname: videoSelected ? videoDataFromChild["name"] : "",
    });
    setVideo(videoDataFromChild);
    //console.log(video);
    // //console.logvideoDataFromChild);
  };

  const setPdfData = (pdfDataa) => {
    // //console.logpdfDataa);
    pdfDataFromChild = pdfDataa;
    pdfSelected = true;
    setSelecteddata({
      ...selecteddata,
      pdfname: pdfSelected ? pdfDataFromChild["name"] : "",
    });
    setPdf(pdfDataFromChild);
    //console.log(pdf);
  };

  const setNumberData = (NumberDataa) => {
    numberDataFromChild = NumberDataa;
    // stringArray = numberDataFromChild.split("\n");

    // //console.log(numberDataFromChild);
    setNumberArray(numberDataFromChild);
  };
  function validate(stringArray1) {
    let phoneRegex = new RegExp("^[6-9]{1}\\d{9}[\r\n]?$");

    for (var i = 0; i < stringArray1.length; i++) {
      if (!stringArray1[i].match(phoneRegex)) {
        modal1.onClose();
        alert.error(
          "Invalid input: " +
            stringArray1[i] +
            "\n" +
            " :  Please write in correct format \n6965656533\n 6565656232",
          { title: "Number Format" }
        );
        return false;
      }
    }

    return true;
  }

  const setMessageData = (messageDataa) => {
    // 😛 Hi hjgfhj
    setSelecteddata({ ...selecteddata, message: messageDataa });
    const encoded = utf8.encode(messageDataa);
    setEncodedMessage(encoded);
    //console.log(encoded);
    // const decoded = utf8.decode(encoded);
    messageDataFromChild = encoded;
  };

  const listofImages = (imageList) => {
    // //console.logimageList);
    imageDataFromChild = imageList;
    imageSelected = true;
    var mapData = [];
    for (var i = 0; i < imageDataFromChild.length; i++) {
      mapData.push({
        data_url: imageDataFromChild[i]["data_url"],
        file: {
          name: imageDataFromChild[i]["file"]["name"],
        },
      });
    }
    imageDataFromChild = mapData;
    // console.warn(mapData.length);
    // //console.log(imageData + "in campaign");
    setSelecteddata({
      ...selecteddata,
      image: imageSelected ? mapData.length : 0,
    });
    setImageList(imageDataFromChild);
    //console.log(imageDataFromChild);
    // //console.logimageDataFromChild);
  };
  function stringGen() {
    // input = document.getElementById("num");
    var result = "";
    var input_length = 8;
    var chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < input_length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    // document.getElementById("result").innerHTML = result;
    return result;
  }
  function handleReviewSubmit(numberList) {
    var isValid = validate(numberList);
    //console.log(isValid);
    if (isValid === true) {
      // console.warn(numberList)
      // console.warn("preview clicked");
      var username = getJWTData();
      createCampaign(
        username["username"],
        // "sagar124",
        // stringArray,
        numberArray,
        "whatsapp_balance",
        // imageSelected ? imageDataFromChild : null,
        // selecteddata.pdfname != "" ? selecteddata.pdfname : null,
        // selecteddata.pdfname != "" ? pdfDataFromChild["base64"] : null,
        // videoSelected ? videoDataFromChild["name"] : null,
        // videoSelected ? videoDataFromChild["base64"] : null,
        pdf["name"],
        pdf["base64"],
        video["name"],
        video["base64"],
        encodedMessage
      );
      modal1.onClose();
    }
  }
  async function createCampaign(
    username,
    phone_numbersList,
    type,
    // imageList,
    pdf_name,
    pdfBase,
    video_name,
    videoBase64,
    messageDataFromChild
  ) {
    var randomId = stringGen();
    var dataToUpload = JSON.stringify({
      // username:"sagar124",
      remark: "WAPP campaign",
      transaction_user: username,
      username: username,
      count: phone_numbersList.length.toString(), // integer
      type: type, //"Whatsapp Campaign",
      // image: {
      imagedata: imageList,
      // },
      pdf_name: selecteddata.pdfname != "" ? pdf_name : null, //pdf name as same formate
      pdf: selecteddata.pdfname != "" ? pdfBase : null, //base64 encode pdf string
      video_name: selecteddata.videoname != "" ? video_name : null, //video name as same formate
      video: selecteddata.videoname != "" ? videoBase64 : null, //base64 encode video string
      content: {
        id: randomId,
        name: "CMP" + randomId,
        phone_numbers: phone_numbersList,

        message: messageDataFromChild,
      },
    });

    try {
      showLoading();
      await axios
        .post(
          `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/create_camp`,
          dataToUpload
        )
        .then((response) => {
          console.warn(response.data);
          modal1.onClose();
          if (response.data["body-json"]["statusCode"] === 200) {
            alert.success(response.data["body-json"]["body"]["campaign"], {
              title: "Success",
            });
            setSelecteddata({
              message: "",
              pdfname: "",
              videoname: "",
              imageList: [],
            });
            //console.log(response.data);
            //show the reference number of created campaign
          } else {
            //console.log(response.data);
            alert.error(
              "Error: " + response.data["body-json"]["body"]["message"],
              {
                title: "Failed",
              }
            );

            setSelecteddata({
              message: "",
              pdfname: "",
              videoname: "",
              imageList: [],
            });
          }
          //console.logresponse);
          // alert(response);
          // setAPIData(...APIData, response.data["body-json"]["body"]);
          // setFoundUsers(...foundUsers, response.data["body-json"]["body"]);
        })
        .then(() => {
          reset();
          hideLoading();
        });
    } catch (error) {
      // alert("err", err);
    }
  }
  function reset() {
    setNumberArray([]);
    setEncodedMessage("");
    setImageList([]);
    setPdf({});
    setVideo({});
  }
  function numberisValidated(validation) {
    //console.log(validation);
    setNumberValidated(validation);
  }

  return (
    <Fragment>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        // </View>
        <Box p="20px" bg={bgColor} my="122px" borderRadius="12px">
          <Box bg="#96F3F1" width="100%" borderRadius="5px">
            {" "}
            <BalanceComponent className="user-div" />
          </Box>
          {/* <Flex direction='column' >  */}
          <Flex direction="column" pt={{ base: "120px", md: "35px" }}>
            <Grid
              // gridAutoColumns={{}}
              templateColumns={{ xl: "repeat(2, 1fr)" }}
              gap="15px"
            >
              <Box width="100%">
                <CardBody>
                  <NumberComponent
                    isPreview={preview}
                    getNumbers={setNumberData}
                    isValidated={numberisValidated}
                  />
                </CardBody>
              </Box>

              <Box width="100%">
                <CardBody>
                  <Flex
                    direction="column"
                    justify="space-around"
                    w="100%"
                    style={{ gap: "13px" }}
                  >
                    <MessageComponent
                      isPreview={preview}
                      getMessages={setMessageData}
                    />

                    <ImageComponent
                      isPreview={preview}
                      listImage={listofImages}
                    />

                    <Grid
                      templateColumns={{ sm: "1fr", sm: "repeat(2, 1fr)" }}
                      gap="22px"
                      mt="20px"
                      mb="10px"
                      className="user-div"
                      pt="20px"
                      pb="20px"
                    >
                      <GridItem>
                        <Card bg="#fbfcff" p="16px" borderRadius="2px">
                          <CardHeader>
                            <CardBody px="15px">
                              <VideoComponent
                                isPreview={preview}
                                videoBase64={setBase64}
                              />
                            </CardBody>
                            <Text as="b" color="red" fontWeight="400">
                              *Video file size should be less than 3 MB
                            </Text>
                          </CardHeader>
                        </Card>
                      </GridItem>
                      <GridItem>
                        <Card bg="#fbfcff" p="16px" borderRadius="0px">
                          <CardHeader>
                            <CardBody px="15px">
                              <PdfComponent
                                isPreview={preview}
                                pdfdata={setPdfData}
                              />
                            </CardBody>
                            <Text as="b" color="red" fontWeight="400">
                              *Pdf file size should be less than 1 MB
                            </Text>
                          </CardHeader>
                        </Card>
                      </GridItem>
                    </Grid>
                  </Flex>
                </CardBody>
              </Box>
            </Grid>
            <Box p="20px" align="center">
              {numberArray.length > 0 && numberValidated ? (
                <PreviewBox
                  onClose={modal1.onClose}
                  onOpen={modal1.onOpen}
                  isOpen={modal1.isOpen}
                  numberCount={numberArray.length}
                  image={selecteddata.image}
                  pdfName={selecteddata.pdfname}
                  videoName={selecteddata.videoname}
                  message={selecteddata.message}
                  // numberList={numberArray}
                  handleReviewSubmit={() => {
                    // console.warn(numberArray);
                    handleReviewSubmit(numberArray);
                  }}
                ></PreviewBox>
              ) : (
                <Text color="blue">
                  * at least one number is required for Proceed
                </Text>
              )}
              {/* } */}
            </Box>
          </Flex>
        </Box>
      )}
    </Fragment>
  );
}

export default CampainDashboard;
