import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User.js";
import ResellerLayout from "layouts/Reseller.js";
import SubadminLayout from "layouts/Subadmin.js";
// import RTLLayout from "layouts/RTL.js"; // Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import SignUp from "views/Pages/SignUp";
import SignIn from "views/Pages/SignIn";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "views/Dashboard/Dashboard";
import "bootstrap/dist/css/bootstrap.css";
import { getUserData } from "./loginui/services/variables";
import { positions, Provider } from "react-alert";
import AlertTemplateBasic from "react-alert-template-basic";
import AlertTemplate from "./AlertTemplate";
import ErrorBoundary from "ErrorBoundary";

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 3500,
  offset: "30px",
  // you can also just use 'scale'
  // transition: transitions.SCALE
};

ReactDOM.render(
  <ChakraProvider theme={theme} resetCss={false} position="relative">
    <ErrorBoundary>
      <Provider template={AlertTemplateBasic} {...options}>
        {/* <Provider template={AlertMUITemplate} {...{ position: positions.MIDDLE }}> */}
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/login`} component={SignIn} />
            <Route path={`/signup`} component={SignUp} />
            {/* <Route path={`/Signup`} component={SignUp} /> */}
            <Route path={`/dashboard`} component={Dashboard} />
            {/* <Route path={`/Dashboard`} component={"/admin/dashboard"} /> */}
            <ProtectedRoute path="/admin" component={AdminLayout} />
            {/* <ProtectedRoute path="/admin" component={AdminLayout} auth={ getUserData}/> */}
            {/* <Route path={`/admin`} component={AdminLayout} /> */}
            <ProtectedRoute path={`/user`} component={UserLayout} />
            <ProtectedRoute path={`/reseller`} component={ResellerLayout} />
            <ProtectedRoute path={`/subadmin`} component={SubadminLayout} />
            {/* <Route path={`/rtl`} component={RTLLayout} /> */}
            {/* <Redirect from={`/`} to="/login" /> */}
            <Route path="/" exact component={SignIn} />
            {/* <Redirect from={`/`} to="/auth" /> */}
          </Switch>
        </HashRouter>
      </Provider>
    </ErrorBoundary>
  </ChakraProvider>,
  document.getElementById("root")
);
