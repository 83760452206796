import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Spinner,
  Center,
  VStack,
} from "@chakra-ui/react";
import {
  getUserData,
  getJWTData,
  getUserRole,
} from "../../loginui/services/variables";
// import { useForm } from "react-hook-form";

// import { getUserData, getJWTData,getUserRole } from "../loginui/services/variables";
import React from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { Formik, Field } from "formik";
export default function CreateAccount({ isOpen, onOpen, onClose, returnHome }) {
  let userRole = getUserRole();
  // console.warn(userRole);
  const [loading, setLoading] = React.useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const initialRef = React.useRef(null);
  const alert = useAlert();
  const optionsAdmin = [
    { label: "Sub Admin", value: "subadmin" },
    { label: "Reseller", value: "reseller" },
    { label: "User", value: "user" },
  ];

  const optionsReseller = [
    { label: "Reseller", value: "reseller" },
    { label: "User", value: "user" },
  ];
  const [role, setRole] = React.useState("");
  const [data, setData] = React.useState({
    name: "",
    username: "",
    phone: "",
    role: "",
    company: "",
    password: "",
    email: "",
    refund_to_parent: null,
    parent_username: "",
    creater_role: "",
    validity: "",
  });
  let selectedRole = "";
  function saveDropDown(value) {
    setRole(value.target.value);
    selectedRole = value.target.value;
    //console.logvalue.target.value);
  }
  //-------------- input box info

  const handleChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value });
    // console.warn(data);
  };
  const isError = data.name === "";
  const isErrorUsername = data.username === "";
  const isErrorPhone = data.phone === "";
  const isErrorEmail = data.email === "";
  const isErrorPassword = data.password === "";
  const isErrorValidity = data.validity === "";
  const isErrorRole = role === "";
  //---------------------

  // create account function

  async function signup(
    name,
    username,
    email,
    phone,

    password,
    validity
  ) {
    var userData = JSON.parse(localStorage.getItem("userData"));
    // showLoading();
    var userData = JSON.stringify({
      name: name,
      username: username,
      phone: phone,
      role: role,
      company: "non",
      password: password,
      email: email,
      refund_to_parent: null,
      parent_username: userData["username"],
      creater_role: userData["role"],
      validity: validity,
    });
    showLoading();
    await axios
      .post(
        `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/register`,
        userData
      )
      .then((response) => {
        if (response.data["body-json"]["statusCode"] != 200) {
          alert.error(response.data["body-json"]["body"], {
            title: "Alert!",
          });
          hideLoading();
          onClose();
        }
        //console.log(response.data["body-json"]["body"]["message"]);
        alert.success(response.data["body-json"]["body"]["message"], {
          title: "Success!",
        });
        hideLoading();
        returnHome(true);
        //  alert.success(
        //    "result :",
        //    response.data["body-json"]["body"]["message"]
        //  );
        // tFoundUsers(...foundUsers, response.data["body-json"]["body"]);
      })
      .then((data) => {
        onClose();
        //  hideLoading();
        //  hidePopup();
      });
  }

  //-----------------------------
  function handleClose() {
    setData({
      name: "",
      username: "",
      phone: "",
      role: "",
      company: "",
      password: "",
      email: "",
      refund_to_parent: null,
      parent_username: "",
      creater_role: "",
      validity: "",
    });
    onClose();
  }
  return (
    <>
      <Button onClick={onOpen}>Add User</Button>

      <Modal
        closeOnOverlayClick={false}
        leastDestructiveRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create user account</ModalHeader>
          <ModalCloseButton />
          {loading ? (
            <Center>
              <Spinner size="xl"></Spinner>
            </Center>
          ) : (
            <ModalBody pb={6}>
              <Formik
                initialValues={{
                  name: "",
                  username: "",
                  phone: "",
                  role: "",
                  company: "",
                  password: "",
                  email: "",
                  refund_to_parent: null,
                  parent_username: "",
                  creater_role: "",
                  validity: "",
                }}
                onSubmit={(values) => {
                  signup(
                    values.name,
                    values.username,
                    values.email,
                    values.phone,

                    values.password,
                    values.validity
                  );
                }}
              >
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack spacing={4} align="flex-start">
                      <FormControl
                        isRequired
                        isInvalid={!!errors.name && touched.name}
                      >
                        <FormLabel>Full name</FormLabel>

                        <Field
                          as={Input}
                          id="name"
                          name="name"
                          type="text"
                          placeholder="NAME"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "Name is required";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isRequired
                        isInvalid={!!errors.username && touched.username}
                        mt={4}
                      >
                        <FormLabel>Username</FormLabel>
                        <Field
                          as={Input}
                          id="username"
                          name="username"
                          type="text"
                          placeholder="USERNAME"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "userame is required";
                            } else if (value.length < 4) {
                              error = "username must have more than 4 digits";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.username}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.password && touched.password}
                        mt={4}
                      >
                        <FormLabel>Password</FormLabel>
                        <Field
                          as={Input}
                          id="password"
                          name="password"
                          type="password"
                          placeholder="PASSWORD"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "password is required";
                            } else if (value.length < 8) {
                              error = "password must be in 8 digits";
                            }
                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.email && touched.email}
                      >
                        <FormLabel>Email</FormLabel>
                        <Field
                          as={Input}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="EMAIL"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "email is required";
                            }

                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isRequired
                        isInvalid={!!errors.phone && touched.phone}
                        mt={4}
                      >
                        <FormLabel>Phone</FormLabel>
                        <Field
                          as={Input}
                          id="phone"
                          name="phone"
                          type="tel"
                          placeholder="PHONE"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 10 || value.length > 10) {
                              error = "valid phone no is required";
                            }

                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.phone}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.role && touched.role}
                      >
                        <FormLabel>Select Role</FormLabel>
                        {/* <Input required={true} id="" onChange={handleChange}  placeholder="First name" /> */}

                        <Select
                          required={true}
                          onChange={(value) => saveDropDown(value)}
                        >
                          {userRole === "admin"
                            ? optionsAdmin.map((row, key) => {
                                return (
                                  <option id={key} value={row.value}>
                                    {row.label}
                                  </option>
                                );
                              })
                            : userRole === "reseller"
                            ? optionsReseller.map((row, key) => {
                                return (
                                  <option id={key} value={row.value}>
                                    {row.label}
                                  </option>
                                );
                              })
                            : optionsAdmin.map((row, key) => {
                                return (
                                  <option id={key} value={row.value}>
                                    {row.label}
                                  </option>
                                );
                              })}
                        </Select>
                        {!isErrorRole ? (
                          <FormHelperText>Select Role</FormHelperText>
                        ) : (
                          <FormErrorMessage>Role is required.</FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isRequired
                        isInvalid={!!errors.validity && touched.validity}
                        mt={4}
                      >
                        <FormLabel>validity</FormLabel>
                        <Field
                          as={Input}
                          id="validity"
                          name="validity"
                          type="date"
                          placeholder="Select Validity"
                          // onChange={handleChange}
                          variant="filled"
                          validate={(value) => {
                            let error;
                            if (value.length < 1) {
                              error = "Date is required";
                            }

                            return error;
                          }}
                        />
                        <FormErrorMessage>{errors.validity}</FormErrorMessage>
                      </FormControl>
                      <Button
                        colorScheme="purple"
                        type="submit"
                        width="full"
                        // colorScheme="blue"
                        mr={3}
                      >
                        create
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </ModalBody>
          )}

          <ModalFooter>
            <Button ref={initialRef} onClick={handleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
