import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Spinner,
  useDisclosure,
  Tooltip,
  Switch,
} from "@chakra-ui/react";
import axios from "axios";
import { React, useState, Fragment, useMemo } from "react";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import RadioButton from "components/Tables/RadioButton";
import { getJWTData } from "loginui/services/variables";
import { useAlert } from "react-alert";
import AlertDialogExample from "../alertDialog/alert";
import Pagination from "../../views/Pagination/Pagination";
import ResellerUserBal from "./ResellerUserBal";
import DeleteUser from "./DeleteUser";
import Validity from "./Validity";
import ForgotPassword from "./ForgotPassword";
import "./billingRow.css";
import useAxiosPost from "components/CustomHook/useAxios";

function BillingRow({ name, company, email, isActive, userid, returnHome }) {
  const alert = useAlert();
  const textColor = useColorModeValue("gray.700", "gray.700");
  const bgColor = useColorModeValue("#8cb8d4", "#7474C6"); //#fffff0 //Light cyan #e0ffff //#5EC2BA57
  const nameColor = useColorModeValue("black", "black");
  // const { name, company, email, userid } = props;
  const [credit, setCredit] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [switchData, setSwitchdata] = useState(isActive);
  // const bgColorForInput = useColorModeValue("navy.900", "#5EC2BA57");
  const bgColorForInput = useColorModeValue("#e8f0fe", "#E8FAFE");
  const [select_whatsapp, setWhatsapp] = useState("");
  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [loading, setLoading] = useState(false);
  const modal1 = useDisclosure();
  const modal2 = useDisclosure();
  const modal3 = useDisclosure();
  const Validitymodal4 = useDisclosure();
  const passwordemodal5 = useDisclosure();

  //revert the credit to userId
  function handelRevert() {
    var creditToInt = parseInt(credit);
    if (credit != null && creditToInt > 0 && select_whatsapp != "") {
      // if (confirm("Sure want to revert credits")) {
      var userData = getJWTData();
      var username = userData["username"];
      showLoading();
      axios
        .post(
          "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/sub_credit",
          {
            username: userid,
            whatsapp_balance: creditToInt,
            type: select_whatsapp.toLowerCase(),

            remark: remarks,
            transaction_user: username,
          }
        )
        .then((response) => {
          if (response.data["body-json"]["statusCode"] === 200) {
            hideLoading();
            modal2.onClose();
            setCredit("");
            setRemarks("");
            returnHome(true);
          } else if (response.data["body-json"]["statusCode"] === 500) {
            hideLoading();
            modal2.onClose();

            setCredit("");
            setRemarks("");
            alert.error(response.data["body-json"]["body"], {
              title: "Failed",
            });
          }
        });
    } else {
      if (select_whatsapp != "") {
        hideLoading();
        modal2.onClose();
        alert.show("fill credit field or min debit amount more than 0", {
          title: "alert!",
        });
      } else {
        hideLoading();
        modal2.onClose();
        alert.show("please select Balance type", {
          title: "alert!",
        });
      }
    }
  }
  //Add Credit to userId
  function handelAdd() {
    var creditToInt = parseInt(credit);
    if (
      credit != null &&
      creditToInt > 0 &&
      creditToInt < 2000000 &&
      select_whatsapp != ""
    ) {
      // alert.show("Sure want to add credits!", {
      //   title: "Add Credit",
      //   // onOpen:()=>{},
      //   // onClose:()=>{},
      //   actions: [
      //     {
      //       closeCopy: "Cancel",
      //       copy: "okay",
      //       onClick: () => {
      var userData = getJWTData();
      var username = userData["username"];
      // var role = userData["role"];
      showLoading();
      // var creditToInt = parseInt(credit);
      axios
        .post(
          "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/add_credit",
          {
            username: userid,
            whatsapp_balance: creditToInt,
            type: select_whatsapp.toLowerCase(),
            remark: remarks,
            transaction_user: username,
          }
        )
        .then((response) => {
          if (response.data["body-json"]["statusCode"] === 200) {
            hideLoading();
            modal1.onClose();
            alert.success("Balance Credited");
            setCredit("");
            setRemarks("");
            returnHome(true);
          } else if (response.data["body-json"]["statusCode"] === 500) {
            hideLoading();
            modal1.onClose();

            setCredit("");
            setRemarks("");
            alert.error(response.data["body-json"]["body"], {
              title: "Failed",
            });
          }
        });
    } else {
      if (select_whatsapp != "") {
        modal1.onClose();
        alert.error(
          "fill credit field or min credit amount more than 0 or less than 20L"
        );
        hideLoading();
      } else {
        modal1.onClose();
        alert.error("please select Balance type");
        hideLoading();
      }
    }
  }
  function selected_whatsapp(event) {
    // //console.logevent);
    setWhatsapp(event);
  }
  return (
    <Fragment>
      {loading ? (
        <Spinner
          style={{
            // flex: 1,
            marginTop: 280,
            marginLeft: 400,
            justifyContent: "center",
            alignItems: "center",
            // alignSelf: "center",
          }}
          size="xl"
        ></Spinner>
      ) : (
        <Box p="7px" bg={bgColor} my="2px" borderRadius="5px">
          <Box padding={2}></Box>
          <Flex
            direction="column"
            justify="space-between"
            w="100%"
            style={{ gap: "13px" }}
          >
            <Flex
              direction="row"
              maxWidth="100%"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              {/* <Flex direction="row" maxWidth="70%" sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
              <Text color={nameColor} fontSize="sm" fontWeight="bold" mb="10px">
                {name}
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {userid}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {company}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {email}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {" "}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {" "}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="semibold">
                {" "}
                <Text as="span" color={nameColor}>
                  {" "}
                </Text>
              </Text>
            </Flex>

            <Flex direction="row" maxWidth="100%" sx={{ display: "flex" }}>
              <Text color="black" fontSize="sm" fontWeight="semibold">
                Type :{" "}
              </Text>
              <Box style={{ paddingRight: "10px" }}></Box>
              <RadioButton selected_whatsapp={selected_whatsapp} />
            </Flex>

            <Flex
            // style={{ gap: "13px" }}
            // direction={{ sm: "column", md: "row" }}
            // align="flex-start"
            // maxWidth="100%"
            // sx={{ display: "flex", justifyContent: "space-between" }}
            // p={{ md: "20px" }}
            >
              {" "}
              <div className="billing-row">
                <div className="billing-row-in">
                  <div>
                    <Box>
                      <FormLabel fontSize="1em" fontWeight="semibold">
                        Credit:
                      </FormLabel>
                    </Box>
                    <Flex>
                      <Input
                        id="credit"
                        color="black"
                        bg={bgColorForInput}
                        variant="auth"
                        // fontSize="lg"
                        type="number"
                        pattern="[0-9]*"
                        maxLength={5}
                        value={credit}
                        placeholder="Enter Credit for add/revert"
                        m="5px"
                        fontSize="0.9em"
                        size="md"
                        _placeholder={{ opacity: 0.7, color: "inherit" }}
                        onChange={(e) => {
                          setCredit((v) =>
                            e.target.validity.valid ? e.target.value : v
                          );
                          // //console.log"Credit : ", e.target.value);
                        }}
                      />
                    </Flex>
                  </div>
                  <div>
                    <FormLabel fontSize="1em" fontWeight="semibold">
                      Remarks:
                    </FormLabel>
                    <Flex>
                      <Input
                        id="remarks"
                        color="black"
                        bg={bgColorForInput}
                        // variant="auth"
                        // fontSize="lg"
                        ms="2px"
                        type="text"
                        value={remarks}
                        placeholder="add remarks/note"
                        mt="5px"
                        mb="2px"
                        fontSize="0.9em"
                        size="md"
                        _placeholder={{ opacity: 0.7, color: "inherit" }}
                        onChange={(e) => {
                          setRemarks(e.target.value);
                          // //console.log"Credit : ", e.target.value);
                        }}
                      />
                    </Flex>
                  </div>
                </div>
                {/* <Button
                p="0px"
                colorScheme="teal"
                variant="solid"
                // bg="transparent"
                // variant="no-effects"
                onClick={handelAdd}
              >
                <Flex
                  color={textColor}
                  cursor="pointer"
                  align="center"
                  p="12px"
                >
                  <Icon as={FaPencilAlt} me="4px" />
                  <Text fontSize="sm" fontWeight="semibold">
                    Add Credit
                  </Text>
                </Flex>
              </Button> */}
                <div style={{ padding: "36px 0 0 50px" }}>
                  <Flex
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Tooltip
                      hasArrow
                      label="add balance to user"
                      bg="gray.300"
                      color="black"
                    >
                      <Box style={{ paddingRight: "10px" }}>
                        <AlertDialogExample
                          isOpen={modal1.isOpen}
                          onClose={modal1.onClose}
                          onOpen={modal1.onOpen}
                          btnName="Add Credit"
                          btnOkName="Add Credit"
                          dialogName="Alert"
                          colorScheme="teal"
                          dialogmsg="Definitly want to add credit(s) to user"
                          handleClick={handelAdd}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="get back balance"
                      bg="gray.300"
                      color="black"
                    >
                      <Box style={{ paddingRight: "20px" }}>
                        <AlertDialogExample
                          isOpen={modal2.isOpen}
                          onClose={modal2.onClose}
                          onOpen={modal2.onOpen}
                          btnName="Revert Credit"
                          dialogName="Revert Alert"
                          colorScheme="red"
                          dialogmsg="Definitly want to revert credit(s) to user"
                          btnOkName="Revert Credit"
                          handleClick={handelRevert}
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="view balance"
                      bg="gray.300"
                      color="black"
                    >
                      {/* <Tooltip label="View balance"> */}
                      <Box style={{ paddingLeft: "30px" }}>
                        <ResellerUserBal
                          onClose={modal3.onClose}
                          onOpen={modal3.onOpen}
                          isOpen={modal3.isOpen}
                          userid={userid}
                          // userRole={getUserRole}
                        ></ResellerUserBal>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label={switchData ? "Activated" : "Deactivated"}
                      bg="gray.300"
                      color="black"
                    >
                      {/* <Tooltip label="View balance"> */}
                      <Box style={{ paddingLeft: "20px" }}>
                        <Switch
                          isChecked={switchData}
                          onChange={() => {
                            setSwitchdata(!switchData);
                            // isActive = !isActive;
                            // console.log("switch clicked", !switchData);
                            // console.log("switch clicked", userid);
                            var url =
                              "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/account";
                            var jsonData = JSON.stringify({
                              username: userid,
                              active: !switchData,
                            });
                            // showLoading();
                            axios.post(url, jsonData).then((data) => {
                              if (
                                data.data["body-json"]["statusCode"] === 200
                              ) {
                                // console.log(
                                //   "sucess data : ",
                                //   data.data["body-json"]
                                // );
                                // setData(data["body-json"]["body"]);
                                // hideLoading();
                                alert.success(
                                  !switchData
                                    ? "User Activated"
                                    : "User Deactivated"
                                );
                              } else {
                                alert.error("Something went wrong");
                              }
                            });
                          }}
                          size="lg"
                        />
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label=" Update Validity"
                      bg="gray.300"
                      color="black"
                    >
                      {/* <Tooltip label="View balance"> */}
                      <Box style={{ paddingLeft: "10px" }}>
                        <Validity
                          onClose={Validitymodal4.onClose}
                          onOpen={Validitymodal4.onOpen}
                          isOpen={Validitymodal4.isOpen}
                          userid={userid}
                          // userRole={getUserRole}
                        ></Validity>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      label="change password"
                      bg="gray.300"
                      color="black"
                    >
                      {/* <Tooltip label="View balance"> */}
                      <Box style={{ paddingLeft: "10px" }}>
                        <ForgotPassword
                          onClose={passwordemodal5.onClose}
                          onOpen={passwordemodal5.onOpen}
                          isOpen={passwordemodal5.isOpen}
                          userid={userid}
                          // userRole={getUserRole}
                        ></ForgotPassword>
                      </Box>
                    </Tooltip>
                  </Flex>
                </div>
              </div>
            </Flex>
          </Flex>
        </Box>
      )}
    </Fragment>
  );
}

export default BillingRow;
