import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Icon,
  Link,
  Switch,
  Text,
  Spinner,
  Center,
  useColorModeValue,
} from "@chakra-ui/react";
import { getJWTData } from "loginui/services/variables";
import axios from "axios";
import { useAlert } from "react-alert";
// Assets
import signInImage from "assets/img/signInImage.png";
import "components/globalVar/globalvariables";
//import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

function Logout() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "#1C3FAA");
  const titleColor = useColorModeValue("gray.700", "blue.500");
  const colorIcons = useColorModeValue("gray.700", "white");
  const bgIcons = useColorModeValue("trasnparent", "navy.700");
  const bgIconsHover = useColorModeValue("gray.50", "whiteAlpha.100");
  const navigate = useHistory();

  const [loading, setLoading] = React.useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const alert = useAlert();
  useEffect(() => {
    //console.log"Exucute useEffect");
  
    showLoading();
  
    setTimeout(() => {
      logout();
    }, 2000);
  });
  async function saveLastLogin(username) {
    // var userData = JSON.parse(localStorage.getItem("userData"));
    showLoading();
    var userData = JSON.stringify({
      username: username,
      
    });
 
    console.warn(userData);
    // setShowBalance(true);
    await axios
      .post(
        `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/lastlogin`,userData
        // { data: userData }
      )
      .then((response) => {
        if (response.data["body-json"]["statusCode"] != 200) {
          alert.error("Something went wrong! try again");
           
       
          
        } else {
         
          alert.success("Logged out");
        }
      });
  }

  // const [isLoggedin, setIsLoggedin] = useState(false);

  // <button onClickCapture={logout}>logout user</button>

  // const [tot, setTot] =  useState(); // take tot in a state

  const logout = () => {
    var userData = getJWTData();
    var username = userData["username"];
    saveLastLogin(username);
    localStorage.removeItem("token-info");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    hideLoading();
    navigate.replace("/login", { replace: true });
    // setIsLoggedin(false);
  };

  return loading ? (
    <Center>
      <Spinner size="xl"></Spinner>
    </Center>
  ) : (
    <></>
  );
}

export default Logout;
