import { React, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Textarea,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  Stack,
} from "@chakra-ui/react";

function TextComponent(props) {
  // let [value, setValue] = useState(["Call Now", "Visit Now"]);
  const bgColorForInput = useColorModeValue("#e8f0fe", "#e8f0fe");
  const [numvalue, setNumValue] = useState({
    callcaption: "",
    number: "",
    linkcaption: "",
    link: "",
  });
  const [wbtName, setWbtnname] = useState({ btnName: "", webbtnName: "" });
  const [btnValue, setBtnvalue] = useState({
    btnCallValue: "",
    btnWebsiteValue: "",
  });
  const [linkvalue, setLinkValue] = useState({
    linkcaption: "",
    link: "",
  });
  const [mapState, setMapState] = useState(new Map());
  const ref = useRef(null);
  const refForValue = useRef(null);
  let handleInputChangeForButtonName = (e) => {
    let inputValue = "";
    let dataButton = [];
    let dataButtonValue = [];
    // inputValue = e.target.value;
    inputValue = refForValue.current.id;
    const call = document.getElementById("Call Caption & Number").value;
    const link = document.getElementById("Link Caption & Link").value;
    // setNumValue({ ...numvalue, callcaption: call });
    // setNumValue({ ...numvalue, linkcaption: link });
    // setData({ ...data, [e.target.id]: e.target.value });
    // setWbtnname({...wbtName, "btnName":call});
    // setMapState(map => new Map(map.set("btnName", call)));
    // setMapState(map => new Map(map.set("webbtnName", call)));
    dataButton.push(call);
    dataButton.push(link);
    // //console.log(dataButton);
    props.getButtonName(dataButton);
    // props.sendValueTextButton(e.target.value);
    // setValue(inputValue);
  };
  // let [valueField, setValueTextField] = useState("");
  // const Component = ({ id }) => {
  //   const node = useRef(document.getElementById(id));

  //   // useEffect(() =>{
  //   //   const el = document.getElementById(id)
  //   //   if(el) node.current = el
  //   // },[id])
  // };
  let handleInputChangeButtonValue = (e) => {
    let inputValue = e.target.value;
    inputValue = ref.current.id;

    var dataButton = [];
    // inputValue = e.target.value;

    const call = document.getElementById("+91 6328921458").value;
    const link = document.getElementById("https://").value;

    // setNumValue({ ...numvalue, number: call });
    // setNumValue({ ...numvalue, link: link });

    dataButton.push(call);
    dataButton.push(link);
    // //console.log(dataButton);
    props.getButtonNameValue(dataButton);
    // //console.log(inputValue);
    // props.setValueTextField(inputValue);
  };
  return (
    <>
      <Flex>
        <Stack direction="row" spacing={12}>
          <Text as="b" color="black" mb="8px">
            {props.title}{" "}
          </Text>
          <Box>
            <Input
              readOnly={props.isPreview}
              ref={refForValue}
              id={props.title}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              color="black"
              // value={props.value}
              bg={bgColorForInput}
              variant="auth"
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder={props.placeholderForCall}
              mb="24px"
              size="lg"
              onChange={handleInputChangeForButtonName}
            />
          </Box>
          <Box>
            <Input
              readOnly={props.isPreview}
              ref={ref}
              id={props.placeholder}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              bg={bgColorForInput}
              color="black"
              variant="auth"
              fontSize="md"
              ms="4px"
              type={props.type}
              placeholder={props.placeholder}
              mb="24px"
              size="lg"
              onChange={handleInputChangeButtonValue}
            />
          </Box>
        </Stack>
      </Flex>
    </>
  );
}

export default TextComponent;
