// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Spinner,
  Center,
  Button,
  IconButton,
  useDisclosure,
  Badge,
  Box,
  Icon,
} from "@chakra-ui/react";

// Custom components
import "./index.css";
import { useTable, useSortBy, usePagination } from "react-table";
import { EditIcon } from "@chakra-ui/icons";
// import { useSortableTable } from "./useSortableTable";
import { getUserData, getJWTData } from "loginui/services/variables";
import avatar9 from "assets/img/avatars/avatar9.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

// import { useTable, useSortBy } from "react-table";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useMemo,
  useRef,
  createRef,
} from "react";
import { useHistory } from "react-router-dom";
import { tablesProjectData, tablesTableData } from "variables/general";
import axios from "axios";
// import ModalExample from "components/ShowPopup/CreatePopup";
import TransitionExample from "components/alertDialog/alert";
import SearchBar from "components/search/search.js";
import { UpdateBoxLoading } from "../../components/alertDialog/updateProcess";
import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import UpdateProcess from "../../components/alertDialog/updateProcess";
import Pagination from "../Pagination/Pagination";
import "../../components/UserDiv/userdiv.css";
let PageSize = 5;
import fileDownload from "js-file-download";
import UpdateModal from "./UpdatePopup";
import DetailsModal from "./DetailsPopup";
function LatestCampaign() {
  // const {loadingDialog} = useContext(UpdateBoxLoading);
  // console.warn(loadingDialog);
  const textColor = useColorModeValue("black", "black");
  const borderColor = useColorModeValue("#CFD9E3", "#5EC2BA57");
  const apiUrl =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/latest_camp";
  const apiUrlReseller =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/camp_report";
  // import avatar9 from "assets/img/avatars/avatar9.png";
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const showLoading = () => setLoading(true);
  const [searchText, setSearchText] = useState("");
  function result(event) {
    //capturing search letter from user

    // filter(event);
    setSearchText(event);
  }
  const ref = useRef(null);
  const refButton = useRef(null);
  const refDetailsButton = useRef(null);
  const modal1 = useDisclosure({ defaultIsOpen: false });
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const hideLoading = () => setLoading(false);

  const [loadingUpdate, setLoadingupdate] = useState(false);
  const showLoadingUpdate = () => setLoadingupdate(true);
  const hideLoadingUpdate = () => setLoadingupdate(false);
  const [apiData, setAPIData] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [sortType, setSortType] = useState("desc");
  const [userRole, setUserRole] = useState("");

  async function downloadCSV(csvUrl, filename) {
    await axios({
      url: csvUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename + ".csv");
    });
  }

  const toggleSort = () => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
  };
  const [campId, setCampid] = useState("");
  function clicked(e, value) {
    e.preventDefault();
    //console.log("clicked", value);
    setCampid(value);
    refButton.current.click();
    // itemRef.current.onClick();

    // showLoadingUpdate();
  }
  const [tableData, setTableData] = useState({
    persion_name: "",
    csv: "",
    message: "",
    time: "",
    pdf_url: "",
    video_url: "",
    name: "",
    count: "1",
    role: "",
    username: "",
    cmp_status: "",
    reinitiated: 0,
    creationdate: "",
    image_url: [],
    id: "",
    remarks: "",
    type: "",
  });
  function detailsButtonClicked(e, value) {
    e.preventDefault();
    //console.log("clicked", value);
    setTableData(value);
    refDetailsButton.current.click();
    // itemRef.current.onClick();

    // showLoadingUpdate();
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "Creator",
        accessor: "persion_name",
        sortable: true,
        sortbyOrder: "desc",
      },
      {
        Header: "Campaign Id ",
        accessor: "id",
        Cell: ({ cell }) => (
          <Flex direction="row">
            <Text>{cell.value}</Text>
            <Icon
              width="40%"
              height="25px"
              as={ViewIcon}
              onClick={(e) => detailsButtonClicked(e, cell.row.original)}
            ></Icon>
          </Flex>
        ),
        sortable: true,
      },
      {
        Header: "Status    ",
        accessor: "cmp_status",
        dataIndex: "cmp_status",
        Cell: ({ cell }) => (
          <Flex direction="row">
            <Badge
              bg={
                cell.value === "inprogress"
                  ? "yellow.400"
                  : cell.value === "completed"
                  ? "green.400"
                  : cell.value === "reinitiated"
                  ? "blue.400"
                  : cell.value === "pending"
                  ? "orange.400"
                  : bgStatus
              }
              color={cell.value === "pending" ? "white" : "white"}
              fontSize="16px"
              p="3px 10px"
              borderRadius="8px"
            >
              {cell.value}
            </Badge>
            <Icon
              width="40%"
              height="25px"
              as={EditIcon}
              onClick={(e) => clicked(e, cell.row.original.id)}
            ></Icon>
            {/* <Button onClick={(e) => clicked(e, cell.row.original.id)}>
              {" "}
              edit
            </Button> */}
          </Flex>
        ),

        sortable: true,
      },
      { Header: "Date  ", accessor: "creationdate", sortable: true },
      { Header: "Time  ", accessor: "time", sortable: false },
      {
        Header: "Download",
        accessor: "csv",
        Cell: ({ cell }) => (
          <IconButton
            ref={ref}
            colorScheme="teal"
            aria-label="CSV Download"
            size="lg"
            icon={<DownloadIcon />}
            // onClick={() => //console.log(cell)}
            onClick={() => downloadCSV(cell.value, cell.row.original.id)}
          />
        ),
        sortable: false,
      },
    ],
    []
  );
  const sortees = React.useMemo(
    () => [
      {
        id: "creationdate",
        desc: false,
      },
      {
        id: "time",
        desc: true,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data: apiData,
      initialState: {
        sortBy: sortees,
      },
    },
    useSortBy,
    usePagination
  );
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  //---------------------------------------------

  useEffect(() => {
    var userData = getJWTData();
    console.log(userData["role"]);
    setUserRole(userData["role"]);
    getLatestCamp();
  }, []);

  async function getLatestCamp() {
    var auth = getUserData();
    var userData = getJWTData();
    var username = userData["username"];
    var role = userData["role"];
    if (auth) {
      var dataToUpload = JSON.stringify({
        username: username,
      });
      switch (role) {
        case "admin":
          //console.logrole);
          showLoading();
          await axios
            .get(apiUrl)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"];

              // var sortedData = data
              // console.warn(data);
              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "subadmin":
          // //console.logrole);
          showLoading();
          await axios
            .get(apiUrl)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"];
              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "reseller":
          // //console.logrole);
          showLoading();
          await axios
            .post(apiUrlReseller, dataToUpload)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"];
              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "user":
          // //console.logrole);
          showLoading();
          await axios
            .post(apiUrlReseller, dataToUpload)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"];

              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;

        default:
          break;
      }
    } else {
      history.push("/login");
    }
  }

  useEffect(() => {}, [apiData]);
  function result(event) {
    if (event.length > 0) {
      // console.warn(event.length);
      searchUser(event);
    } else {
      getLatestCamp();
    }
  }

  const [loadingsearch, setLoadingsearch] = useState(false);
  const showSearchLoading = () => setLoadingsearch(true);

  const hideSearchLoading = () => setLoadingsearch(false);
  async function searchUser(query) {
    showSearchLoading();
    await axios
      .get(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/campaign_search?key=" +
          query
      )
      .then((res) => {
        if (apiData.length > 0) {
          // console.warn(res.data);
          var data = res.data["body"]["Items"];

          setAPIData(data);
          // setFoundUsers(res.data["body"]["message"]);
          hideSearchLoading();
          //console.logres.data["message"]);
        }
      });
  }
  function reloadHomeAfterUpdate(value) {
    // //console.log(value);
    if (value === true) {
      getLatestCamp();
    }
  }
  return (
    <Fragment>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
            <Flex
              style={{ gap: "13px" }}
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              maxWidth="70%"
              sx={{ display: "flex", justifyContent: "space-between" }}
              p={{ md: "20px" }}
            >
              {/* <ModalExample></ModalExample> */}

              <SearchBar result={(e) => result(e.target.value)}></SearchBar>
              <>
                <UpdateModal
                  buttonRef={refButton}
                  campId={campId}
                  reloadHome={reloadHomeAfterUpdate}
                ></UpdateModal>
                <DetailsModal
                  buttonRef={refDetailsButton}
                  tableData={tableData}
                  // reloadHome={reloadHomeAfterUpdate}
                ></DetailsModal>
                {/* <UpdateProcess
                  buttonRef={refButton}
                  isOpen={modal1.isOpen}
                  onClose={modal1.onClose}
                  onOpen={modal1.onOpen}
                  campId={"abcd"}
                  // handleCallBack={handleCallback}
                ></UpdateProcess> */}
              </>
            </Flex>
          </Card>
          <Card
            bg="#f3f3f3"
            overflowX={{ sm: "scroll", xl: "hidden" }}
            pb="0px"
            marginTop="10px"
          >
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Latest Campaign
              </Text>
            </CardHeader>
            <CardBody>
              <Table className="table table-bordered" {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup) => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <Th
                          userSelect="none"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <Flex alignItems="center">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <ChevronDownIcon ml={1} w={4} h={4} />
                              ) : (
                                <ChevronUpIcon ml={1} w={4} h={4} />
                              )
                            ) : (
                              ""
                            )}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <Td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              {/* <Table className="table" variant="simple" color={textColor}>
                <Thead>
                  <Tr
                    my=".8rem"
                    pl="0px"
                    color="black"
                    borderTop="2px solid red"
                    borderBottom="2px solid black"
                  >
                    {columns.map(({ label, accessor,sortable }) => {
                      const cl = sortable
                      ? sortField === accessor && order === "asc"
                       ? "up"
                       : sortField === accessor && order === "desc"
                       ? "down"
                       : "default"
                      : "";
     return <Th  className={cl}  onClick={sortable ? () => handleSortingChange(accessor) : null} pl="0px" key={accessor} borderColor={borderColor} color="black">
     {label}
   </Th>;
    })}
                   
                  </Tr>
                </Thead>
                <Tbody>
                  {currentTableData && currentTableData.length > 0 ? (
                    currentTableData
                      .sort((a, b) => {
                        if (sortType === "asc") {
                          let _a = new Date(a.creationdate);
                          let _b = new Date(b.creationdate);
                          return _a === _b ? 0 : _a > _b ? 1 : -1;
                        } else {
                          let _a = new Date(a.creationdate);
                          let _b = new Date(b.creationdate);
                          return _a === _b ? 0 : _a < _b ? 1 : -1;
                        }
                      })
                      .map((row, index, arr) => {
                        return (
                          <TablesTableRow
                            name={row.persion_name}
                            logo={avatar9}
                            email={row.username}
                            subdomain={row.role}
                            domain={row.id}
                            status={row.cmp_status}
                            date={row.creationdate}
                            csvUrl={row.csv}
                            time={row.time}
                            isLast={index === arr.length - 1 ? true : false}
                            key={index}
                          />
                        );
                      })
                  ) : (
                    <h1 style={{ color: "black" }}>No results found!</h1>
                  )}
                </Tbody>
              </Table> */}
            </CardBody>
          </Card>
        </Flex>
      )}
      <>
        <Box marginTop="10px">
          <Center>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </Center>
        </Box>
      </>
      {/* <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={apiData.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      /> */}
    </Fragment>
  );
}

export default LatestCampaign;
