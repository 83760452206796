// Chakra Icons
import { BellIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
  ProfileIcon,
  SettingsIcon,
} from "components/Icons/Icons";
import axios from "axios";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SearchBar } from "components/Navbars/SearchBar/SearchBar";
import { SidebarResponsive } from "components/Sidebar/SidebarUser";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "user_routes.js";
import { getJWTData } from "loginui/services/variables";
// import BalanceComponent from "components/Balance";
export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "#1C3FAA");
  if (secondary) {
    navbarIcon = "white";
  }
  // const [balance, setUserBalance] = useState([
  //   { api_balance: 0, whatsapp_balance: 0, whatsapp_button_balance: 0 },
  // ]);
  // useEffect(() => {
  //   var username = getJWTData();
  //   //console.logusername["username"]);
  //   var dataUsername = JSON.stringify({ username: username["username"] });
  //   axios
  //     .post(
  //       "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/credit_user",
  //       dataUsername
  //     )
  //     .then((response) => {
  //       //console.logresponse["data"]["body-json"]["body"]["Items"]);
  //       setUserBalance(response["data"]["body-json"]["body"]["Items"][0]);
  //       //console.logresponse["data"]["body-json"]["body"]["Items"][0]);
  //     });
  // }, []);
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {/* <BalanceComponent></BalanceComponent> */}
      {/* <Stack direction="row" spacing={3}>
        <Box>
          {" "}
          <Text color="black" as="b">
            WB: {balance["whatsapp_balance"]}
          </Text>{" "}
        </Box>
        <Spacer></Spacer>
        <Box>
          {" "}
          <Text color="black" as="b">
            WBB: {balance["whatsapp_button_balance"]}
          </Text>{" "}
        </Box>
        <Spacer></Spacer>
        <Box>
          {" "}
          <Text color="black" as="b">
            API: {balance["api_balance"]}
          </Text>{" "}
        </Box>
     
        <Spacer></Spacer>
        <Spacer></Spacer>
      </Stack> */}
      {/* <SearchBar me='18px' />
      <NavLink to='/auth/signin'>
        <Button
          ms='0px'
          px='0px'
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant='no-effects'
          rightIcon={
            document.documentElement.dir ? (
              ""
            ) : (
              <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />
            )
          }
          leftIcon={
            document.documentElement.dir ? (
              <ProfileIcon color={navbarIcon} w='22px' h='22px' me='0px' />
            ) : (
              ""
            )
          }>
          <Text display={{ sm: "none", md: "flex" }}>Sign In</Text>
        </Button>
      </NavLink> */}
      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === "dark" ? (
              <h1>Web</h1>
            ) : (
              // <ArgonLogoLight w='74px' h='27px' />
              <h1>Tis</h1>
              // <ArgonLogoDark w='74px' h='27px' />
            )}
            <Box
              w="1px"
              h="20px"
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              // <Text> </Text>
              <h1>Web</h1>
            ) : (
              // <ArgonLogoLight w='74px' h='27px' />
              <h1>Tis</h1>
              // <ChakraLogoDark w='82px' h='21px' />
            )}
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />
      <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px" bg={menuBg}>
          <Flex flexDirection="column">
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="13 minutes ago"
                info="from Alicia"
                boldInfo="New Message"
                aName="Alicia"
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius="8px" mb="10px">
              <ItemContent
                time="2 days ago"
                info="by Josh Henry"
                boldInfo="New Album"
                aName="Josh Henry"
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius="8px">
              <ItemContent
                time="3 days ago"
                info="Payment succesfully completed!"
                boldInfo=""
                aName="Kara"
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}
