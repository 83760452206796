// // Chakra imports
// import {
//   Flex,
//   Table,
//   Tbody,
//   Text,
//   Th,
//   Thead,
//   Tr,
//   useColorModeValue,
//   Spinner,
//   Button,
//   Center
// } from "@chakra-ui/react";
// // Custom components
// import { getUserData, getJWTData } from "loginui/services/variables";
// import avatar9 from "assets/img/avatars/avatar9.png";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import TablesProjectRow from "components/Tables/TablesProjectRow";
// import TablesTableRow from "components/Tables/TablesTableRow";
// import UsersTableRow from "components/Tables/UserCampaignTable";
// import React, {
//   useState,
//   useEffect,
//   Fragment,
//   useContext,
//   useMemo,
// } from "react";
// import { useHistory } from "react-router-dom";
// import { tablesProjectData, tablesTableData } from "variables/general";
// import axios from "axios";
// import ModalExample from "components/ShowPopup/CreatePopup";
// import TransitionExample from "components/alertDialog/alert";
// import SearchBar from "components/search/search.js";
// import { UpdateBoxLoading } from "../../components/alertDialog/updateProcess";

// import Pagination from "../Pagination/Pagination";
// import "../../components/UserDiv/userdiv.css";
// let PageSize = 5;

// function LatestCampaignUser() {
//   // const {loadingDialog} = useContext(UpdateBoxLoading);
//   // console.warn(loadingDialog);
//   const textColor = useColorModeValue("black", "black");
//   const borderColor = useColorModeValue("#CFD9E3", "#5EC2BA57");
//   const apiUrl =
//     "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/latest_camp";
//   const apiUrlReseller =
//     "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/camp_report";
//   // import avatar9 from "assets/img/avatars/avatar9.png";
//   const [loading, setLoading] = useState(false);
//   let history = useHistory();
//   const showLoading = () => setLoading(true);
//   const [searchText, setSearchText] = useState("");
//   function result(event) {
//     //capturing search letter from user

//     // filter(event);
//     setSearchText(event);
//   }
//   const hideLoading = () => setLoading(false);
//   const [apiData, setAPIData] = useState([]);
//   const [foundUsers, setFoundUsers] = useState([]);
//   const [sortType, setSortType] = useState("desc");
//   // function handleSort(apiData) {
//   //   var data = apiData.sort((a, b) => {
//   //     let _a = new Date(a.creationdate);
//   //     let _b = new Date(b.creationdate);
//   //     return _a === _b ? 0 : _a > _b ? 1 : -1;
//   //   });
//   //   // console.warn(data);
//   // }

//   const toggleSort = () => {
//     sortType === "asc" ? setSortType("desc") : setSortType("asc");
//   };

//   //Pagination
//   const [currentPage, setCurrentPage] = useState(1);

//   const currentTableData = useMemo(() => {
//     const firstPageIndex = (currentPage - 1) * PageSize;
//     const lastPageIndex = firstPageIndex + PageSize;
//     return apiData.slice(firstPageIndex, lastPageIndex);
//   }, [currentPage, apiData]);

//   //---------------------------------------------

//   useEffect(() => {
//     getLatestCamp();
//   }, []);

//   async function getLatestCamp() {
//     var auth = getUserData();
//     var userData = getJWTData();
//     var username = userData["username"];
//     var role = userData["role"];
//     if (auth) {
//       var dataToUpload = JSON.stringify({
//         username: username,
//       });
//       switch (role) {
//         case "admin":
//           //console.logrole);
//           showLoading();
//           await axios
//             .get(apiUrl)
//             .then((response) => {
//               var data = response.data["body-json"]["body"]["Items"].sort(
//                 (a, b) => {
//                   const date1 = new Date(a.creationdate);
//                   const date2 = new Date(b.creationdate);
//                   date1 - date2;
//                 }
//               );

//               // var sortedData = data
//               console.warn(data);
//               setAPIData(data);
//             })
//             .then((data) => hideLoading());
//           break;
//         case "subadmin":
//           // //console.logrole);
//           showLoading();
//           await axios
//             .get(apiUrl)
//             .then((response) => {
//               var data = response.data["body-json"]["body"]["Items"].sort(
//                 (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
//               );

//               setAPIData(data);
//             })
//             .then((data) => hideLoading());
//           break;
//         case "reseller":
//           // //console.logrole);
//           showLoading();
//           await axios
//             .post(apiUrlReseller, dataToUpload)
//             .then((response) => {
//               var data = response.data["body-json"]["body"]["Items"].sort(
//                 (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
//               );

//               setAPIData(data);
//             })
//             .then((data) => hideLoading());
//           break;
//         case "user":
//           // //console.logrole);
//           showLoading();
//           await axios
//             .post(apiUrlReseller, dataToUpload)
//             .then((response) => {
//               var data = response.data["body-json"]["body"]["Items"].sort(
//                 (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
//               );

//               setAPIData(data);
//             })
//             .then((data) => hideLoading());
//           break;

//         default:
//           break;
//       }
//     } else {
//       history.push("/login");
//     }
//   }
//   // useEffect(() => {

//   //   if(searchText != ""){
//   //   const searchUser = async () => {
//   //     const res = await axios.get(
//   //       "https://qdnz77sv02.execute-api.ap-south-1.amazonaws.com?key=" +
//   //         searchText
//   //       // {
//   //       //   params: {
//   //       //     query: searchText,
//   //       //   },
//   //       // }
//   //     );
//   //     // console.logres.data);
//   //   };

//   //   }

//   // }, [searchText]);
//   useEffect(() => {}, [apiData]);
//   function result(event) {
//     if (event.length > 0) {
//       // console.warn(event.length);
//       searchUser(event);
//     } else {
//       getLatestCamp();
//     }
//   }

//   const [loadingsearch, setLoadingsearch] = useState(false);
//   const showSearchLoading = () => setLoadingsearch(true);

//   const hideSearchLoading = () => setLoadingsearch(false);
//   async function searchUser(query) {
//     showSearchLoading();
//     await axios
//       .get(
//         "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/campaign_search?key=" +
//           query
//       )
//       .then((res) => {
//         if (apiData.length > 0) {
//           // console.warn(res.data);
//           var data = res.data["body"]["Items"];

//           setAPIData(data);
//           // setFoundUsers(res.data["body"]["message"]);
//           hideSearchLoading();
//           //console.logres.data["message"]);
//         }
//       });
//   }
//   return (
//     <Fragment>
//       {loading ? (
//         <Center><Spinner

//           size="xl"
//         ></Spinner></Center>
//       ) : (
//         <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
//           <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
//             <Flex
//               style={{ gap: "13px" }}
//               direction={{ sm: "column", md: "row" }}
//               align="flex-start"
//               maxWidth="70%"
//               sx={{ display: "flex", justifyContent: "space-between" }}
//               p={{ md: "20px" }}
//             >
//               {/* <ModalExample></ModalExample> */}

//               <SearchBar result={(e) => result(e.target.value)}></SearchBar>
//               <Button onClick={toggleSort}>
//                 Sort By <strong> "Date" </strong> {sortType}
//               </Button>
//             </Flex>
//           </Card>
//           <Card
//             bg="#f3f3f3"
//             overflowX={{ sm: "scroll", xl: "hidden" }}
//             pb="0px"
//             marginTop="10px"
//           >
//             <CardHeader p="6px 0px 22px 0px">
//               <Text fontSize="xl" color={textColor} fontWeight="bold">
//                 Latest Campaign
//               </Text>
//             </CardHeader>
//             <CardBody>
//               <Table variant="simple" color={textColor}>
//                 <Thead>
//                   <Tr
//                     my=".8rem"
//                     pl="0px"
//                     color="black"
//                     borderTop="2px solid red"
//                     borderBottom="2px solid black"
//                   >
//                     <Th pl="0px" borderColor={borderColor} color="black">
//                       Creator
//                     </Th>
//                     <Th borderColor={borderColor} color="black">
//                       Campaign Id &amp; Role
//                     </Th>
//                     <Th borderColor={borderColor} color="black">
//                       Status
//                     </Th>
//                     <Th borderColor={borderColor} color="black">
//                       Created
//                     </Th>
//                     <Th borderColor={borderColor}></Th>
//                   </Tr>
//                 </Thead>
//                 <Tbody>
//                   {currentTableData && currentTableData.length > 0 ? (
//                     currentTableData
//                       .sort((a, b) => {
//                         if (sortType === "asc") {
//                           let _a = new Date(a.creationdate);
//                           let _b = new Date(b.creationdate);
//                           return _a === _b ? 0 : _a > _b ? 1 : -1;
//                         } else {
//                           let _a = new Date(a.creationdate);
//                           let _b = new Date(b.creationdate);
//                           return _a === _b ? 0 : _a < _b ? 1 : -1;
//                         }
//                       })
//                       .map((row, index, arr) => {
//                         return (
//                           <UsersTableRow
//                             name={row.persion_name}
//                             logo={avatar9}
//                             email={row.username}
//                             subdomain={row.role}
//                             domain={row.id}
//                             status={row.cmp_status}
//                             date={row.creationdate}
//                             csvUrl={row.csv}
//                             time={row.time}
//                             isLast={index === arr.length - 1 ? true : false}
//                             key={index}
//                           />
//                         );
//                       })
//                   ) : (
//                     <h1 style={{ color: "black" }}>No results found!</h1>
//                   )}
//                 </Tbody>
//               </Table>
//             </CardBody>
//           </Card>
//         </Flex>
//       )}
//       <Pagination
//         className="pagination-bar"
//         currentPage={currentPage}
//         totalCount={apiData.length}
//         pageSize={PageSize}
//         onPageChange={(page) => setCurrentPage(page)}
//       />
//     </Fragment>
//   );
// }

// export default LatestCampaignUser;

// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  useColorModeValue,
  Spinner,
  Center,
  Button,
  IconButton,
  useDisclosure,
  Badge,
  Box,
  Icon,
} from "@chakra-ui/react";
// Custom components
import "./index.css";
import { useTable, useSortBy, usePagination } from "react-table";
import DetailsModal from "./DetailsPopup";
// import { useSortableTable } from "./useSortableTable";
import { getUserData, getJWTData } from "loginui/services/variables";
import avatar9 from "assets/img/avatars/avatar9.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
// import { useTable, useSortBy } from "react-table";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { tablesProjectData, tablesTableData } from "variables/general";
import axios from "axios";
import ModalExample from "components/ShowPopup/CreatePopup";
import TransitionExample from "components/alertDialog/alert";
import SearchBar from "components/search/search.js";
import { UpdateBoxLoading } from "../../components/alertDialog/updateProcess";
import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import UpdateProcess from "../../components/alertDialog/updateProcess";
import Pagination from "../Pagination/Pagination";
import "../../components/UserDiv/userdiv.css";
let PageSize = 5;
import fileDownload from "js-file-download";
function LatestCampaignUser() {
  // const {loadingDialog} = useContext(UpdateBoxLoading);
  // console.warn(loadingDialog);
  const textColor = useColorModeValue("black", "black");
  const borderColor = useColorModeValue("#CFD9E3", "#5EC2BA57");
  const apiUrl =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/latest_camp";
  const apiUrlReseller =
    "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/camp_report";
  // import avatar9 from "assets/img/avatars/avatar9.png";
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const showLoading = () => setLoading(true);
  const [searchText, setSearchText] = useState("");
  function result(event) {
    //capturing search letter from user

    // filter(event);
    setSearchText(event);
  }
  const ref = useRef(null);
  const refDetailsButton = useRef(null);
  const modal1 = useDisclosure();
  const bgStatus = useColorModeValue("gray.400", "navy.900");
  const hideLoading = () => setLoading(false);
  const [apiData, setAPIData] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [sortType, setSortType] = useState("desc");

  async function downloadCSV(csvUrl, filename) {
    await axios({
      url: csvUrl, // File URL Goes Here
      method: "GET",
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, filename + ".csv");
    });
  }
  const [tableData, setTableData] = useState({
    persion_name: "",
    csv: "",
    message: "",
    time: "",
    pdf_url: "",
    video_url: "",
    name: "",
    count: "1",
    role: "",
    username: "",
    cmp_status: "",
    reinitiated: 0,
    creationdate: "",
    image_url: [],
    id: "",
    remarks: "",
    type: "",
  });
  function detailsButtonClicked(e, value) {
    e.preventDefault();
    //console.log("clicked", value);
    setTableData(value);
    refDetailsButton.current.click();
    // itemRef.current.onClick();

    // showLoadingUpdate();
  }

  const toggleSort = () => {
    sortType === "asc" ? setSortType("desc") : setSortType("asc");
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Creator",
        accessor: "persion_name",
        sortable: true,
        sortbyOrder: "desc",
      },
      {
        Header: "Campaign Id ",
        accessor: "id",
        Cell: ({ cell }) => (
          <Flex direction="row">
            <Text>{cell.value}</Text>
            <Icon
              width="40%"
              height="25px"
              as={ViewIcon}
              onClick={(e) => detailsButtonClicked(e, cell.row.original)}
            ></Icon>
          </Flex>
        ),
        sortable: true,
      },
      {
        Header: "Status    ",
        accessor: "cmp_status",
        dataIndex: "cmp_status",
        Cell: ({ cell }) => (
          <Flex direction="row">
            <Badge
              bg={
                cell.value === "inprogress"
                  ? "yellow.400"
                  : cell.value === "completed"
                  ? "green.400"
                  : cell.value === "reinitiated"
                  ? "blue.400"
                  : cell.value === "pending"
                  ? "orange.400"
                  : cell.value === "failed"
                  ? "red"
                  : cell.value === "rejected"
                  ? "red.400"
                  : bgStatus
              }
              color={cell.value === "pending" ? "white" : "white"}
              fontSize="16px"
              p="3px 10px"
              borderRadius="8px"
            >
              {cell.value}
            </Badge>
          </Flex>
        ),
        sortable: true,
      },
      { Header: "Date  ", accessor: "creationdate", sortable: true },
      { Header: "Time  ", accessor: "time", sortable: true },
      {
        Header: "Download",
        accessor: "csv",
        Cell: ({ cell }) => (
          <IconButton
            ref={ref}
            colorScheme="teal"
            aria-label="CSV Download"
            size="lg"
            icon={<DownloadIcon />}
            // onClick={() => //console.log(cell)}
            onClick={() => downloadCSV(cell.value, cell.row.original.id)}
          />
        ),
        sortable: false,
      },
    ],
    []
  );
  const sortees = React.useMemo(
    () => [
      {
        id: "creationdate",
        desc: false,
      },
      {
        id: "time",
        desc: true,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    rows,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data: apiData,
      initialState: {
        sortBy: sortees,
      },
    },
    useSortBy,
    usePagination
  );
  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return rows.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage, apiData]);

  //---------------------------------------------

  useEffect(() => {
    getLatestCamp();
  }, []);

  async function getLatestCamp() {
    var auth = getUserData();
    var userData = getJWTData();
    var username = userData["username"];
    var role = userData["role"];
    if (auth) {
      var dataToUpload = JSON.stringify({
        username: username,
      });
      switch (role) {
        case "admin":
          //console.logrole);
          showLoading();
          await axios
            .get(apiUrl)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"].sort(
                (a, b) => {
                  const date1 = new Date(a.creationdate);
                  const date2 = new Date(b.creationdate);
                  date1 - date2;
                }
              );

              // var sortedData = data
              console.warn(data);
              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "subadmin":
          // //console.logrole);
          showLoading();
          await axios
            .get(apiUrl)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"].sort(
                (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
              );

              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "reseller":
          // //console.logrole);
          showLoading();
          await axios
            .post(apiUrlReseller, dataToUpload)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"].sort(
                (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
              );

              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;
        case "user":
          // //console.logrole);
          showLoading();
          await axios
            .post(apiUrlReseller, dataToUpload)
            .then((response) => {
              var data = response.data["body-json"]["body"]["Items"].sort(
                (a, b) => new Date(b.creationdate) - new Date(a.creationdate)
              );

              setAPIData(data);
            })
            .then((data) => hideLoading());
          break;

        default:
          break;
      }
    } else {
      history.push("/login");
    }
  }

  useEffect(() => {}, [apiData]);
  function result(event) {
    if (event.length > 0) {
      // console.warn(event.length);
      searchUser(event);
    } else {
      getLatestCamp();
    }
  }

  const [loadingsearch, setLoadingsearch] = useState(false);
  const showSearchLoading = () => setLoadingsearch(true);

  const hideSearchLoading = () => setLoadingsearch(false);
  async function searchUser(query) {
    showSearchLoading();
    await axios
      .get(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/campaign_search?key=" +
          query
      )
      .then((res) => {
        if (apiData.length > 0) {
          // console.warn(res.data);
          var data = res.data["body"]["Items"];

          setAPIData(data);
          // setFoundUsers(res.data["body"]["message"]);
          hideSearchLoading();
          //console.logres.data["message"]);
        }
      });
  }

  // const [tableData, setTableData] = useState(tableData1);

  // const [tableData, handleSorting] = useSortableTable(data, columns);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSortingChange = (accessor) => {
    //console.log(accessor);
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    //  handleSorting(accessor, sortOrder);
  };

  // const firstPageRows = rows.slice(0, 20);
  return (
    <Fragment>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
            <Flex
              style={{ gap: "13px" }}
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              maxWidth="70%"
              sx={{ display: "flex", justifyContent: "space-between" }}
              p={{ md: "20px" }}
            >
              {/* <ModalExample></ModalExample> */}

              <SearchBar result={(e) => result(e.target.value)}></SearchBar>
              <DetailsModal
                buttonRef={refDetailsButton}
                tableData={tableData}
                // reloadHome={reloadHomeAfterUpdate}
              ></DetailsModal>
              {/* <Button onClick={toggleSort}>
                Sort By <strong> "Date" </strong> {sortType}
              </Button> */}
            </Flex>
          </Card>
          <Card
            bg="#f3f3f3"
            overflowX={{ sm: "scroll", xl: "hidden" }}
            pb="0px"
            marginTop="10px"
          >
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                Latest Campaign
              </Text>
            </CardHeader>
            <CardBody>
              <div class="table-responsive">
                <Table className="table table-bordered" {...getTableProps()}>
                  <Thead>
                    {headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <Th
                            userSelect="none"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            <Flex alignItems="center">
                              {column.render("Header")}
                              {/* Add a sort direction indicator */}
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ChevronDownIcon ml={1} w={4} h={4} />
                                ) : (
                                  <ChevronUpIcon ml={1} w={4} h={4} />
                                )
                              ) : (
                                ""
                              )}
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <Tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <Td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Flex>
      )}
      <>
        <Box marginTop="10px">
          <Center>
            <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </Button>
            <Button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </Button>
          </Center>
        </Box>
        <br />
        <div>Showing the first 10 results of {rows.length} </div>
      </>
    </Fragment>
  );
}

export default LatestCampaignUser;
