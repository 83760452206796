import React, { useState, useRef, useContext } from "react";
import {
  Box,
  Button,
  Center,
  Spinner,
  Flex,
  Icon,
  Text,
  Textarea,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  Spacer,
} from "@chakra-ui/react";
import { omit } from "lodash";
import "./../../components/UserDiv/userdiv.css";
import { HSeparator } from "components/Separator/Separator";
import axios from "axios";
// import {GlobalInfo} from '../../views/Dashboard/Campaign_btn_api';
function NumberComponent({ getNumbers, isValidated }) {
  // const {isPreview} =useContext(GlobalInfo);
  // console.warn(isPreview);
  const ref = useRef(null);
  let [value, setValue] = useState("");
  const bgColorForInput = useColorModeValue("white", "#e8f0fe"); //#5EC2BA57
  let [errorMessage, setErrorMessage] = useState("");
  const [number, setNumber] = React.useState([]);
  const [previewNumber, setPreviewNum] = React.useState([]);
  const [preview, setView] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [numberResponse, setNumberResponse] = useState({
    correct: 0,
    incorrect: 0,
    numberList: [],
  });

  const handleInputChange = (e) => {
    let inputValue = ref.current.value;

    //  var string = e.target.value;
    var stringList = inputValue.split("\n");
    setNumber(stringList);
    //console.log(stringList);
    // getNumbers(number);
    // getNumbers(numberResponse.numberList);
  };
  async function checkNumber(stringList) {
    var numList = JSON.stringify({ number: stringList });
    setNumberValidated(true);
    setView(true);
    setLoading(true);
    await axios
      .post(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/number_correct",
        numList
      )
      .then((res) => {
        if (res.data["body-json"]["statusCode"] === 200) {
          // //console.log(res.body["body-json"]["body"]);
          setNumberResponse({
            ...numberResponse,
            correct: res.data["body-json"]["body"]["new correct Number count"],
            incorrect: res.data["body-json"]["body"]["error count"],
            numberList: res.data["body-json"]["body"]["new correct Number"],
          });

          setLoading(false);
          getNumbers(res.data["body-json"]["body"]["new correct Number"]);
          isValidated(true);
        } else {
          setLoading(false);
        }
        // "body-json" : {"statusCode":200,"body":{"error Number":[],"new correct Number":["8956553548","8989565687"],"error count":0,"new correct Number count":2}}
      });
  }
  function editNumber(stringList) {
    // var numList = JSON.stringify({ number: stringList });
    // var data=stringList.join('\n');
    // setPreviewNum(data)
    isValidated(false);
    setView(false);
    setNumberValidated(false);
  }

  return (
    <>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        <Box pb="20px" className="user-div" bg="">
          <Box
            pt="10px"
            pl="10px"
            ml="-14px"
            mr="-14px"
            mb="14px"
            borderTopRightRadius="10px"
            borderTopLeftRadius="10px"
            borderBottom="2px green solid"
            backgroundColor="lightgreen"
          >
            <Text size="lg" as="b" color="black" mb="8px">
              Numbers: Correct Number: {numberResponse.correct} Incorrect
              Number: {numberResponse.incorrect}
            </Text>
          </Box>
          <Spacer></Spacer>

          {preview ? (
            <Textarea
              borderRadius="0px"
              variant="outline"
              minHeight="890px"
              minWidth="150px"
              bg="white"
              ref={ref}
              color="green"
              value={numberResponse.numberList.join("\n")}
              readOnly={true}
              errorBorderColor={errorMessage ? "#a9a9a9" : "#a9a9a9"}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              type="number"
              // onChange={handleInputChange}
              isInvalid
              placeholder="Enter Number(s) As Shown In The Format:  
          6982148567
          9874563255"
              size="lg"
            />
          ) : (
            <Textarea
              borderRadius="0px"
              variant="outline"
              minHeight="890px"
              minWidth="150px"
              bg="white"
              ref={ref}
              color="green"
              defaultValue={numberResponse.numberList.join("\n")}
              // value={numberResponse.numberList.join("\n")}
              errorBorderColor={errorMessage ? "#a9a9a9" : "#a9a9a9"}
              _placeholder={{ opacity: 0.7, color: "inherit" }}
              type="number"
              onChange={handleInputChange}
              isInvalid
              placeholder="Enter Number(s) As Shown In The Format:  
          6982148567
          9874563255"
              size="lg"
            />
          )}
        </Box>
      )}
      <HSeparator></HSeparator>
      <Center>
        <Box pt="15px">
          <Flex direction={"row"}>
            {preview ? (
              <Button
                size="lg"
                colorScheme="teal"
                variant="solid"
                onClick={() => editNumber(numberResponse.numberList)}
              >
                Edit
              </Button>
            ) : null}
            {!preview ? (
              <Button
                size="lg"
                colorScheme="teal"
                variant="solid"
                onClick={() => checkNumber(number)}
              >
                Validate Number
              </Button>
            ) : null}
          </Flex>
        </Box>
      </Center>
      {/* {errorMessage && <div className="error"> {errorMessage} </div>} */}
    </>
  );
}

export default NumberComponent;
