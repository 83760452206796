import { React, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Stack,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
function RadioButton(props) {
  // const [selected_whatsapp, setGender] = useState("Whatsapp Balance");
  const bgColor = "gray";
  const bgColorForInput = useColorModeValue("blue", "blue");
  const [value, setValue] = useState(null);
  function onChangeValue(event) {
    setValue(event);
    // setGender(event.target.value);
    //console.logevent);
    props.selected_whatsapp(event);
  }
  return (
    // whatsapp_button_balance
    // api_balance
    //whatsapp_balance
    <RadioGroup onChange={onChangeValue} value={value}>
      <Stack direction="row" spacing={5}>
        <Radio
          bg={bgColorForInput}
          colorScheme="green"
          value="whatsapp_balance"
        >
          WAPP Balance
        </Radio>
        <Radio
          bg={bgColorForInput}
          colorScheme="green"
          value="whatsapp_button_balance"
        >
          WAPP Button Balance
        </Radio>
        <Radio bg={bgColorForInput} colorScheme="green" value="api_balance">
          WAPP Api
        </Radio>
        {/* <Radio bg={bgColorForInput} colorScheme="green" value="none">
          None
        </Radio> */}
      </Stack>
    </RadioGroup>
  );
}

export default RadioButton;
{
  /* <div onChange={onChangeValue}>
        <input
          type="radio"
          value="Whatsapp Balance"
          name="gender"
          checked={selected_whatsapp === "Whatsapp Balance"}
        />{" "}
        Whatsapp Balance
        <div class="row row-cols-lg-auto"></div>
        <input
          type="radio"
          value="Whatsapp Button Balance"
          name="gender"
          checked={selected_whatsapp === "Whatsapp Button Balance"}
        />{" "}
        Whatsapp Button Balance
        <div class="row row-cols-lg-auto"></div>
        <input
          type="radio"
          value="Whatsapp Api"
          name="gender"
          checked={selected_whatsapp === "Whatsapp Api"}
        />{" "}
        Whatsapp Api
      </div> */
}
