import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  IconButton,
  Spinner,
  Center,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import {
  getUserData,
  getJWTData,
  // getUserRole,
} from "../../loginui/services/variables";
// import { getUserData, getJWTData,getUserRole } from "../loginui/services/variables";
import React from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import Balance from "components/Balance";
export default function ResellerUserBal({ isOpen, onOpen, onClose, userid }) {
  // let userRole = getUserRole();
  // console.warn(userRole);
  const initialRef = React.useRef(null);
  const alert = useAlert();
  const [showBalance, setShowBalance] = React.useState(false);
  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [loading, setLoading] = React.useState(false);
  const [role, setRole] = React.useState("");
  const [data, setData] = React.useState({
    username: "",
  });
  const [balance, setBalance] = React.useState({
    api_balance: 0,
    whatsapp_balance: 0,
    whatsapp_button_balance: 0,
  });
  // "Item":{"api_balance":0,"date":"14/3/2023","whatsapp_balance":-11,"person_name":"test2","whatsapp_button_balance":0,"time":"5:24:05 pm","username":"test2","total_balance":-11}}}

  //-------------- input box info
  // React.useEffect(() => {
  //   getBalance(userid);
  // }, []);
  const handleChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value });
    console.warn(data);
  };
  const handleClose = () => {
    setShowBalance(false);
    onClose();
    setBalance({
      api_balance: 0,
      whatsapp_balance: 0,
      whatsapp_button_balance: 0,
    });
  };

  //---------------------

  // create account function

  async function getBalance(username) {
    // var userData = JSON.parse(localStorage.getItem("userData"));
    showLoading();
    var userData = JSON.stringify({
      username: username,
    });
    setShowBalance(true);
    await axios
      .post(
        `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/balance_check`,
        userData
      )
      .then((response) => {
        if (response.data["body-json"]["statusCode"] != 200) {
          alert.error(response.data["body-json"]["body"], {
            title: "Alert!",
          });
          setShowBalance(false);
          onClose();
        }

        // //console.log(response.data);
        setBalance({
          ...balance,
          whatsapp_balance:
            response.data["body-json"]["body"]["Item"]["whatsapp_balance"],
          api_balance:
            response.data["body-json"]["body"]["Item"]["api_balance"],
          whatsapp_button_balance:
            response.data["body-json"]["body"]["Item"][
              "whatsapp_button_balance"
            ],
        });

        // //console.log(balance);
        // alert.show(response.data["body-json"]["body"]["message"], {
        //   title: "Success!",
        // });
        //  alert.success(
        //    "result :",
        //    response.data["body-json"]["body"]["message"]
        //  );
        // tFoundUsers(...foundUsers, response.data["body-json"]["body"]);
      })
      .then((data) => {
        // onClose();
        hideLoading();
        //  hidePopup();
      });
  }

  //-----------------------------

  return (
    <>
      <IconButton icon={<SearchIcon />} onClick={onOpen}>
        Check Balance
      </IconButton>

      <Modal leastDestructiveRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Check User Balance</ModalHeader>
          <ModalCloseButton />
          {loading ? (
            // <View style={styles.spinnerStyle}>
            <Center>
              <Spinner
                // style={{
                //   // flex: 1,
                //   marginTop: 100,
                //   marginLeft: 100,
                //   justifyContent: "center",
                //   alignItems: "center",
                //   // alignSelf: "center",
                // }}
                size="xl"
              ></Spinner>
            </Center>
          ) : (
            <ModalBody pb={6}>
              <FormControl onSubmit={null}>
                <FormLabel>Username</FormLabel>
                <Input
                  readOnly={true}
                  value={userid}
                  required={true}
                  id="username"
                  onChange={handleChange}
                  placeholder="Username"
                />
                {showBalance ? (
                  <div>
                    <FormLabel>Balance</FormLabel>
                    <Text>Whatsapp Balance</Text>
                    <Input
                      readOnly={true}
                      id="wbbalance"
                      placeholder="Whatsapp Balance"
                      value={balance.whatsapp_balance}
                    />
                    <Text>Whatsapp Button Balance</Text>
                    <Input
                      readOnly={true}
                      id="wbbbalance"
                      placeholder="Whatsapp Button Balance"
                      value={balance.whatsapp_button_balance}
                    />
                    <Text>API Balance</Text>
                    <Input
                      readOnly={true}
                      id="apibalance"
                      placeholder="API Balance"
                      value={balance.api_balance}
                    />
                  </div>
                ) : null}
              </FormControl>
            </ModalBody>
          )}
          <ModalFooter>
            <Button
              disabled={showBalance}
              type="submit"
              onClick={() => {
                getBalance(userid);
              }}
              colorScheme="blue"
              mr={3}
            >
              Check
            </Button>
            <Button ref={initialRef} onClick={handleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
