import decode from "jwt-decode";
import { useHistory } from "react-router-dom";
let access_token = {};

let userData = {};

export const setAccessToken = (token) => {
  access_token = token["token"]["access_token"];

  var decoded = decode(access_token);
  // //console.log(decoded);
  localStorage.setItem("userAccessToken", JSON.stringify(decoded));
  var userDataFromStorage = JSON.parse(localStorage.getItem("userData"));
  userData = JSON.parse(localStorage.getItem("userData"));
  // //console.log(userDataFromStorage)
  setUserData(userDataFromStorage);
  // //console.logdecode(access_token));
};

export const getAccessToken = () => {
  return access_token;
};

export const setUserData = (data) => {
  userData = data;
};
export const getJWTData = () => {
  // let navigate = useHistory();
  // if (userData === null) return navigate.push("/login");
  return JSON.parse(localStorage.getItem("userData"));
};
export const getUserRole = () => {
  // let navigate = useHistory();
  var userRole = JSON.parse(localStorage.getItem("userData"));
  // if (userRole === undefined) return "";
  return userRole["role"];
};

export const getUserData = () => {
  // console.log(userData);

  var userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData);
  if (userData === null) {
    // console.log(userData);
    return false;
  }
  if (userData["role"] === "admin") {
    // //console.log'admin true');
    return true;
  } else if (userData["role"] === "subadmin") {
    // //console.log'subadmin admin true');
    return true;
  } else if (userData["role"] === "reseller") {
    // //console.log'reseller true');
    return true;
  } else if (userData["role"] === "user") {
    // //console.log'user true');
    return true;
  } else {
    return false;
  }

  // return userData;
};

export const navUser = () => {
  let navigate = useHistory();
  let userData = getAccessToken();
  if (userData["role"] === "admin") {
    //console.log'admin true');
    // hideLoading();
    navigate.push("/admin/dashboard");
  } else if (userData["role"] === "subadmin") {
    //console.log'subadmin true');
    // hideLoading();
    navigate.push("/reseller/dashboard");
  } else if (userData["role"] === "reseller") {
    //console.log'reseller true');
    // hideLoading();
    navigate.push("/reseller/dashboard");
  } else {
    // hideLoading();
    navigate.push("/user/dashboard");
  }
};
