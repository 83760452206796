// // Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Spacer,
  Text,
  useColorMode,
  useColorModeValue,
  Spinner,
  Center,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  FormControl,
  FormLabel,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import "./index.css";
import "../../components/UserDiv/userdiv.css";
import { useAlert } from "react-alert";
import {
  getUserData,
  getJWTData,
  // getUserRole,
} from "loginui/services/variables";
import BalanceComponent from "components/Balance";
//Fro Popup
import Modal from "react-bootstrap/Modal";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SearchBar from "components/search/search.js";
// import ModalExample from "components/ShowPopup/CreatePopup.js";
import IconBox from "components/Icons/IconBox";

import { HSeparator } from "components/Separator/Separator";
import BillingRow from "components/Tables/BillingRow";

import React, {
  useState,
  useEffect,
  Fragment,
  useReducer,
  useMemo,
} from "react";
import axios from "axios";
// import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { useHistory } from "react-router-dom";
// import { Container } from "components/popup/Container/Container.js";
import { Popup } from "components/popup/popup";
import LoginPopup from "views/Pages/Login2.js";
import { SearchIcon } from "@chakra-ui/icons";
import CreateAccount from "./CreateUser";
//
import Pagination from "../Pagination/Pagination";
import "../../components/UserDiv/userdiv.css";
// import "../../components/UserDiv/UserDiv";
let PageSize = 5;

function Billing() {
  // Chakra color mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const textColor = useColorModeValue("black", "black");
  const borderColor = useColorModeValue("#dee2e6", "transparent");
  const nameColor = useColorModeValue("black", "black");
  const bgColorForInput = useColorModeValue("navy.900", "#5EC2BA57");
  const searchIconColor = useColorModeValue("black", "black");
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const { colorMode } = useColorMode();
  let history = useHistory();
  const alert = useAlert();
  // //console.logcolorMode);
  const modal1 = useDisclosure();
  const modal2 = useDisclosure();

  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [APIData, setAPIData] = useState([]);
  const [foundUsers, setFoundUsers] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const hidePopup = () => setShowPopUp(false);
  const showPopup = () => setShowPopUp(true);

  // let showPopUp = false;

  //---------------------------------------------

  //Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return foundUsers.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, foundUsers]);

  //---------------------------------------------

  useEffect(() => {
    getUserList();
  }, []);

  async function getUserList() {
    var auth = getUserData();

    if (auth) {
      showLoading();
      // userRole = getJWTData();
      var userData = getJWTData();
      //  console.warn(userData.username);
      var data = JSON.stringify({
        username: userData.username,
      });
      await axios
        .post(
          `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/user_list`,
          data
        )
        .then((response) => {
          if (response.data["body-json"]["statusCode"] === 200) {
            // console.warn(response.data);
            setFoundUsers(response.data["body-json"]["body"]);
          }
          // setAPIData(...APIData, response.data["body-json"]["body"]);
        })
        .then((data) => {
          hideLoading();
        });
    } else {
      history.push("/login");
    }
  }

  // search user
  // GET
  // link :  https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/search_user

  // {
  // "value":"string"
  // }

  //   //searching data

  useEffect(() => {}, [foundUsers]);
  function result(event) {
    if (event.length > 0) {
      // console.warn(event.length);
      searchUser(event);
    } else {
      getUserList();
    }
  }
  // const searchKeys = ["username", "email", "parent_username", "name"];

  const [loadingsearch, setLoadingsearch] = useState(false);
  const showSearchLoading = () => setLoadingsearch(true);

  const hideSearchLoading = () => setLoadingsearch(false);
  async function searchUser(query) {
    showSearchLoading();
    await axios
      .get(
        "https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/search_user?key=" +
          query
      )
      .then((res) => {
        if (foundUsers.length >= 0) {
          setFoundUsers(res.data["body"]["message"]);
          hideSearchLoading();
          //console.logres.data["message"]);
        }
      });
  }
  const columns = [
    { label: "Name", accessor: "Name", sortable: true },
    { label: "User Id ", accessor: "userId", sortable: true },
    { label: "Parent user    ", accessor: "parent", sortable: true },
    { label: "Email id  ", accessor: "email", sortable: true },
    // { label: "Email id  ", accessor: "email" , sortable: true,sortbyOrder: "desc"},
    { label: "view Balance", accessor: "balance", sortable: false },
  ];

  function reRenderAfterCreate(value) {
    // //console.log(value);
    if (value === true) {
      getUserList();
      // getLatestCamp();
    }
  }
  return (
    <Fragment>
      {loading ? (
        <Center>
          <Spinner size="xl"></Spinner>
        </Center>
      ) : (
        <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
          <Card my={{ lg: "24px" }} me={{ lg: "24px" }}>
            <Flex
              style={{ gap: "13px" }}
              direction={{ sm: "column", md: "row" }}
              align="flex-start"
              maxWidth="70%"
              sx={{ display: "flex", justifyContent: "space-around" }}
              p={{ md: "20px" }}
            >
              <SearchBar result={(e) => result(e.target.value)}></SearchBar>
              {/* <h6>Search by name, username, email id</h6> */}
              <CreateAccount
                onClose={modal1.onClose}
                onOpen={modal1.onOpen}
                isOpen={modal1.isOpen}
                returnHome={reRenderAfterCreate}
                // userRole={getUserRole}
              ></CreateAccount>
            </Flex>
          </Card>

          {
            <Card bg="#f3f3f3" my={{ lg: "24px" }} me={{ lg: "24px" }}>
              <Flex direction="column">
                <Flex
                  direction="row"
                  maxWidth="100%"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  borderBottom="2px solid red"
                >
                  <CardHeader py="12px" width="100%">
                    {/* <Flex
                      direction="row"
                      maxWidth="100%"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    > */}
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      borderBottom="2px solid gray"
                      width="100%"
                    >
                      User Details
                    </Text>
                    {/* <Box width="750px"></Box> */}

                    <Box bg="#f3f3f3" width="450px">
                      {" "}
                      <BalanceComponent />
                    </Box>
                    {/* </Flex> */}
                  </CardHeader>
                </Flex>

                <Flex
                  direction="row"
                  maxWidth="93%"
                  pl="10px"
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Text
                    color={nameColor}
                    fontSize="md"
                    fontWeight="bold"
                    mb="10px"
                  >
                    Name
                  </Text>
                  <Text
                    color={nameColor}
                    fontSize="md"
                    fontWeight="bold"
                    mb="10px"
                  >
                    User ID
                  </Text>
                  <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    Incharge Name
                  </Text>
                  <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    Email Address
                  </Text>
                  {/* <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    {"  "}
                  </Text> */}

                  <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    View Balance
                  </Text>

                  <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    Enable/Disable User
                  </Text>
                  <Text color={nameColor} fontSize="sm" fontWeight="semibold">
                    update validity
                  </Text>
                </Flex>

                {loadingsearch ? (
                  <Center>
                    <Spinner size="xl"></Spinner>
                  </Center>
                ) : (
                  <CardBody>
                    <Flex
                      direction="column"
                      w="100%"
                      borderTop="2px solid gray"
                    >
                      {currentTableData && currentTableData.length > 0 ? (
                        currentTableData.map((row, key) => {
                          return (
                            <BillingRow
                              name={row.name}
                              userid={row.username}
                              company={row.parent_username}
                              email={row.email}
                              isActive={row.active}
                              key={key}
                              returnHome={reRenderAfterCreate}
                            />
                          );
                        })
                      ) : (
                        <h1 style={{ color: "black" }}>No results found!</h1>
                      )}
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={foundUsers.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </Flex>
                  </CardBody>
                )}
              </Flex>
            </Card>
          }
        </Flex>
      )}
    </Fragment>
  );
}

export default Billing;
