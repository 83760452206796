// Chakra imports
import {
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";
import { BellIcon, CalendarIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import IconBox from "components/Icons/IconBox";
// Custom icons
import { getUserData, getJWTData } from "loginui/services/variables";
import { useHistory } from "react-router-dom";
// &#x20B9;
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
  // BellIcon,
  NotificationIcon,
} from "components/Icons/Icons.js";
import React, { useEffect, useState } from "react";
// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
} from "variables/charts";
import { pageVisits, socialTraffic } from "variables/general";
import "components/globalVar/globalvariables";

export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");
  const [userDatavalue, setuserDatavalue] = useState({});
  const [numberCount, setNumberCount] = useState({ sum: 0, sum1: 0 });
  const { colorMode } = useColorMode();
  let history = useHistory();
  // let lastLogin = {};
  useEffect(() => {
    var auth = getUserData();
    if (auth) {
      var data = getJWTData();
      var numberCount = JSON.parse(localStorage.getItem("numberCount"));
      // console.warn(numberCount);
      setuserDatavalue(data);
      setNumberCount(numberCount);
      // console.warn(userDatavalue);
    } else {
      history.push("/login");
    }
  }, []);
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" mb="20px">
        <Card minH="125px">
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Total WAPP sent
                </StatLabel>
                <Flex direction="column">
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {/* &#x20B9;53,897 */}
                    Total : {numberCount.sum}
                  </StatNumber>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {/* &#x20B9;53,897 */}
                    Today : {numberCount.sum1}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {/* <Text color="gray.400" fontSize="sm">
              <Text as="span" color="green.400" fontWeight="bold">
                +3.48%{" "}
              </Text>
              Since last month
            </Text> */}
          </Flex>
        </Card>
        <Card minH="125px">
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Last Login
                </StatLabel>
                <Flex>
                  <Text>{""}</Text>
                  <Text>{""}</Text>
                  {/* <Text fontSize="lg" color={textColor} fontWeight="bold">{lastLogin.lastlogin}</Text> */}
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {userDatavalue.lastlogin}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {/* <Text color="gray.400" fontSize="sm">
              <Text as="span" color="green.400" fontWeight="bold">
                +5.2%{" "}
              </Text>
              Since last month
            </Text> */}
          </Flex>
        </Card>
        <Card minH="125px">
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Validity
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    10 Years
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <CalendarIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            {/* <Text color="gray.400" fontSize="sm">
              <Text as="span" color="red.500" fontWeight="bold">
                -2.82%{" "}
              </Text>
              Since last month
            </Text> */}
          </Flex>
        </Card>
        <Card minH="125px">
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Notification
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="xl" color={textColor} fontWeight="bold">
                    0
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius="50%"
                as="box"
                h={"45px"}
                w={"45px"}
                bg={iconBlue}
              >
                <BellIcon h={"24px"} w={"24px"} color={iconBoxInside} />
                {/* <NotificationIcon h={"24px"} w={"24px"} color={iconBoxInside} /> */}
              </IconBox>
            </Flex>
            {/* <Text color="gray.400" fontSize="sm">
              <Text as="span" color="green.400" fontWeight="bold">
                +8.12%{" "}
              </Text>
              Since last month
            </Text> */}
          </Flex>
        </Card>
      </SimpleGrid>
    </Flex>
  );
}
