import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  GridItem,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import encodeUtf8 from "encode-utf8";
import axios from "axios";
import { getUserData } from "loginui/services/variables";
import { useHistory } from "react-router-dom";
import BalanceComponent from "components/Balance";
import PreviewBoxBtn from "./PreviewBoxBtn";
// import { React, useState } from "react";
import {
  useState,
  useCallback,
  Fragment,
  useEffect,
  createContext,
} from "react";
import { Spinner } from "@chakra-ui/spinner";
import NumberComponent from "components/campains/number.js";
import MessageComponent from "components/campains/message.js";
import TextComponent from "components/campains/Text.js";
import TextWithNumberComponent from "components/campains/TextWithNumber.js";
import ImageComponent from "components/campains/image.js";
import VideoComponent from "components/campains/videoupload.js";
import PdfComponent from "components/campains/pdfupload.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import utf8 from "utf8";
import Alert from "components/alertDialog/alert";
import { getJWTData } from "loginui/services/variables";

import { useAlert } from "react-alert";
import "../../components/UserDiv/userdiv.css";
import PreviewBox from "./PreviewBox";

function CampainDashboardButton(props) {
  const textColor = useColorModeValue("gray.700", "white");
  // const bgColor = useColorModeValue("#F8F9FA", "navy.700");
  const [encodedMessage, setEncodedMessage] = useState("");
  const bgColor = useColorModeValue("#e8f0fe", "#e8f0fe");
  const nameColor = useColorModeValue("gray.500", "white");
  const { name, company, email, userid } = props;
  const modal1 = useDisclosure();
  let [listofImage, resultlistofImages] = useState([]);
  let [VideoData, setVideoBase64] = useState({});
  let [pdfData, setpdfBase64] = useState({});
  let [NumberData, setNumberBase64] = useState("");
  const [error, setError] = useState("");
  const alert = useAlert();
  let startUploading = false;
  let pdfDataFromChild;
  let videoDataFromChild;
  let imageDataFromChild;
  let numberDataFromChild;
  let buttonNameaFromChild;
  let buttonDataFromChild;
  let buttonNameFromChildWaMe;
  let buttonDataFromChildWaMe;
  let buttonDataFromChildMsg;
  let messageDataFromChild = "";
  let stringArray;
  let pdfSelected = false;
  let videoSelected = false;
  let imageSelected = false;
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const showLoading = () => setLoading(true);

  const hideLoading = () => setLoading(false);
  const [preview, setPreview] = useState(false);
  const showPreview = () => setPreview(true);

  const hidePreview = () => setPreview(false);
  const [imageList, setImageList] = useState([]);

  const [video, setVideo] = useState({});
  const [pdf, setPdf] = useState({});

  const [numberArray, setNumberArray] = useState([]);
  const [buttonName, setButtonname] = useState([]);
  const [numberValidated, setNumberValidated] = useState(false);
  const [wbtName, setWbtnname] = useState([]);
  const [btnValue, setBtnvalue] = useState([]);
  const [wameCaption, setWameCaption] = useState({
    btnCaptionName: "",
    btnCaptionNumber: "",
    btnCaptionMsg: "",
  });

  const [selecteddata, setSelecteddata] = useState({
    message: "",
    pdfname: "",
    videoname: "",

    wamecaption: "",
    wamenumber: "",
    wamemsg: "",
    imageList: [],
  });

  useEffect(() => {
    var auth = getUserData();
    if (auth) {
    } else {
      history.push("/login");
    }
  }, []);

  const setBase64 = (videoData) => {
    videoDataFromChild = videoData;
    videoSelected = true;
    setSelecteddata({
      ...selecteddata,
      videoname: videoSelected ? videoDataFromChild["name"] : "",
    });
    setVideo(videoDataFromChild);

    //console.logvideoDataFromChild);
  };
  const setButtonData = (messageDataa) => {
    // if (messageDataa != []) {
    // 😛 Hi hjgfhj
    buttonNameaFromChild = messageDataa;
    // //console.log(buttonNameaFromChild);
    if (messageDataa != []) {
      // setSelecteddata({
      //   ...selecteddata,
      //   callcaption: buttonNameaFromChild[0],
      // });
      // setSelecteddata({
      //   ...selecteddata,
      //   linkcaption: buttonNameaFromChild[1],
      // });

      setWbtnname({ buttonNameaFromChild });
      // setWbtnWebsiteData({
      //   ...wbtnWebsiteData,
      //   btnWebsiteName: buttonNameaFromChild[1],
      // });

      //console.log(buttonNameaFromChild);
    }

    // setNumberBase64(NumberDataa);
  };
  const setButtonDataValue = (messageDataa) => {
    if (messageDataa != []) {
      // 😛 Hi hjgfhj
      buttonDataFromChild = messageDataa;

      // //console.log(selecteddata.link);
      if (messageDataa != []) {
        // setSelecteddata({
        //   ...selecteddata,
        //   callnum: buttonDataFromChild[0],
        // });
        // //console.log(selecteddata.callnum);
        // setSelecteddata({
        //   ...selecteddata,
        //   link: buttonDataFromChild[1],
        // });
        setButtonname(buttonDataFromChild);
        setBtnvalue({ buttonDataFromChild });
        //console.log(buttonDataFromChild);
        // setWbtnWebsiteData({
        //   ...wbtnWebsiteData,
        //   btnWebsiteValue: buttonDataFromChild[1],
        // });
      }
      //console.logbuttonDataFromChild);
    }

    // setNumberBase64(NumberDataa);
  };
  const setButtonDataWaMe = (messageDataa) => {
    // if (messageDataa != []) {
    // 😛 Hi hjgfhj
    buttonNameFromChildWaMe = messageDataa;
    // //console.log(buttonNameFromChildWaMe);
    setWameCaption({ ...wameCaption, btnCaptionName: buttonNameFromChildWaMe });
    // }

    // setNumberBase64(NumberDataa);
  };
  const setButtonDataValueWaMe = (messageDataa) => {
    // 😛 Hi hjgfhj
    buttonDataFromChildWaMe = messageDataa;
    // //console.log(buttonDataFromChildWaMe);
    setWameCaption({
      ...wameCaption,
      btnCaptionNumber: buttonDataFromChildWaMe,
    });
  };
  const setButtonDataValueMsg = (messageDataa) => {
    // 😛 Hi hjgfhj
    buttonDataFromChildMsg = messageDataa;
    // //console.log(buttonDataFromChildMsg);
    setWameCaption({ ...wameCaption, btnCaptionMsg: buttonDataFromChildMsg });
  };

  const setPdfData = (pdfDataa) => {
    // //console.logpdfDataa);
    pdfDataFromChild = pdfDataa;
    pdfSelected = true;
    setSelecteddata({
      ...selecteddata,
      pdfname: pdfSelected ? pdfDataFromChild["name"] : "",
    });
    setPdf(pdfDataFromChild);
  };

  const setNumberData = (NumberDataa) => {
    // //console.logNumberDataa);
    numberDataFromChild = NumberDataa;
    // setNumberArray(stringArray);
    setNumberArray(numberDataFromChild);
  };
  function validate(stringArray1) {
    let phoneRegex = new RegExp("^[6-9]{1}\\d{9}[\r\n]?$");
    // let phoneRegex = new RegExp("^[6-9]\\d{0,9}(,)[\r\n]?$");
    // if(stringArray===[]){
    for (var i = 0; i < stringArray1.length; i++) {
      if (!stringArray1[i].match(phoneRegex)) {
        modal1.onClose();

        alert.error(
          "Invalid input: " +
            stringArray1[i] +
            " :  Please write in correct format \n6965656533\n 6565656232",
          { title: "Number Format" }
        );

        return false;
      }
    }
    setError("");

    return true;
  }

  const setMessageData = (messageDataa) => {
    // if (messageDataa != "") {
    // 😛 Hi hjgfhj
    setSelecteddata({ ...selecteddata, message: messageDataa });
    const encoded = utf8.encode(messageDataa);
    const decoded = utf8.decode(encoded);
    setEncodedMessage(encoded);
    messageDataFromChild = encoded;
  };

  const listofImages = (imageList) => {
    // //console.logimageList);
    imageDataFromChild = imageList;
    imageSelected = true;
    var mapData = [];
    for (var i = 0; i < imageDataFromChild.length; i++) {
      mapData.push({
        data_url: imageDataFromChild[i]["data_url"],
        file: {
          name: imageDataFromChild[i]["file"]["name"],
        },
      });
    }
    imageDataFromChild = mapData;
    setSelecteddata({
      ...selecteddata,
      image: imageSelected ? mapData.length : 0,
    });
    setImageList(imageDataFromChild);

    //console.logimageDataFromChild);
    // //console.logimageDataFromChild);
  };
  function stringGen() {
    // input = document.getElementById("num");
    var result = "";
    var input_length = 8;
    var chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < input_length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    // document.getElementById("result").innerHTML = result;
    return result;
  }
  function handleReviewSubmit(numberList) {
    var isValid = validate(numberList);
    if (isValid === true) {
      // console.warn(numberList)
      // console.warn("preview clicked");
      var username = getJWTData();
      createCampaign(
        username["username"],
        // "sagar124",
        // stringArray,
        numberArray,
        "whatsapp_button_balance",
        // imageSelected ? imageDataFromChild : null,
        // pdfSelected ? pdfDataFromChild["name"] : null,
        // pdfSelected ? pdfDataFromChild["base64"] : null,
        // videoSelected ? videoDataFromChild["name"] : null,
        // videoSelected ? videoDataFromChild["base64"] : null,
        pdf["name"],
        pdf["base64"],
        video["name"],
        video["base64"],
        encodedMessage,
        wbtName.length != [] ? wbtName : null,
        btnValue.length != [] ? btnValue : null,
        wameCaption.btnCaptionName != "" ? wameCaption.btnCaptionName : null,
        wameCaption.btnCaptionNumber != ""
          ? wameCaption.btnCaptionNumber
          : null,
        wameCaption.btnCaptionMsg != "" ? wameCaption.btnCaptionMsg : null
      );
      modal1.onClose();
    }
  }
  function createCampaign(
    username,
    phone_numbersList,
    type,
    // imageList,
    pdf_name,
    pdfBase,
    video_name,
    videoBase64,
    messageDataFromChild,
    buttonNameList,
    buttonDataList,
    waButtonName,
    waButtonNumber,
    waButtonMsg
  ) {
    var randomId = stringGen();
    var dataToUpload = JSON.stringify({
      // username:"sagar124",
      remark: "wappb campaign",
      transaction_user: username,
      username: username,
      count: phone_numbersList.length.toString(), // integer
      type: type, //"Whatsapp Campaign",
      // image: {
      imagedata: imageList,
      // },
      pdf_name: selecteddata.pdfname != "" ? pdf_name : null, //pdf name as same formate
      pdf: selecteddata.pdfname != "" ? pdfBase : null, //base64 encode pdf string
      video_name: selecteddata.videoname != "" ? video_name : null, //video name as same formate
      video: selecteddata.videoname != "" ? videoBase64 : null, //base64 encode video string
      //base64 encode video string
      buttonNameList: buttonNameList,
      buttonDataList: buttonDataList,
      waButtonName: waButtonName,
      waButtonNumber: waButtonNumber,
      waButtonMsg: waButtonMsg,
      content: {
        id: randomId,
        name: "CMP" + randomId,
        phone_numbers: phone_numbersList,

        message: messageDataFromChild,
      },
    });

    try {
      showLoading();
      axios
        .post(
          `https://d3ioa5kdb9.execute-api.ap-south-1.amazonaws.com/Tis_whatsapp_be/create_camp`,
          dataToUpload
        )
        .then((response) => {
          console.warn(response.data);
          modal1.onClose();
          if (response.data["body-json"]["statusCode"] === 200) {
            alert.success(response.data["body-json"]["body"]["campaign"], {
              title: "Success",
            });
            setSelecteddata({
              message: "",
              pdfname: "",
              videoname: "",

              wamecaption: "",
              wamenumber: "",
              wamemsg: "",
              imageList: [],
            });
            //console.log(response.data);
          } else {
            //console.log(response.data);
            alert.error(
              "Error: " + response.data["body-json"]["body"]["message"],
              {
                title: "Failed",
              }
            );

            setSelecteddata({
              message: "",
              pdfname: "",
              videoname: "",

              wamecaption: "",
              wamenumber: "",
              wamemsg: "",
              imageList: [],
            });
          }

          //console.logresponse);
          // alert(response);
          // setAPIData(...APIData, response.data["body-json"]["body"]);
          // setFoundUsers(...foundUsers, response.data["body-json"]["body"]);
        })
        .then((data) => {
          reset();
          hideLoading();
          // //console.log(data);
        });
    } catch (error) {
      // alert("err", err);
    }
  }
  function reset() {
    setNumberArray([]);
    setEncodedMessage("");
    setImageList([]);
    setPdf({});
    setVideo({});
    setWbtnname([]);
    setBtnvalue([]);
  }
  function numberisValidated(validation) {
    //console.log(validation);
    setNumberValidated(validation);
  }
  return (
    // <Spinner size="xl" />
    // startUploading?<Spinner size="xl" />
    <Fragment>
      {loading ? (
        // <View style={styles.spinnerStyle}>
        <Center>
          <Spinner
            // style={{
            //   flex: 1,
            //   marginTop: 280,
            //   marginLeft: 400,
            //   justifyContent: "center",
            //   alignItems: "center",
            //   // alignSelf: "center",
            // }}
            size="xl"
          ></Spinner>
        </Center>
      ) : (
        // </View>
        <Box p="20px" bg={bgColor} my="122px" borderRadius="12px">
          <Box bg="#96F3F1" width="450px" borderRadius="5px">
            {" "}
            <BalanceComponent />
          </Box>
          {/* <Flex direction='column' >  */}
          <Flex direction="column" pt={{ base: "120px", md: "35px" }}>
            <Grid
              templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }}
              gap="15px"
            >
              <CardBody px="15px">
                <NumberComponent
                  isPreview={preview}
                  getNumbers={setNumberData}
                  isValidated={numberisValidated}
                />
              </CardBody>
              <Box width="100%">
                <Flex
                  direction="column"
                  justify="space-around"
                  w="100%"
                  style={{ gap: "13px" }}
                >
                  <MessageComponent
                    isPreview={preview}
                    getMessages={setMessageData}
                  />

                  <div className="user-div" bg="#C5CAE2">
                    <Card
                      p="10px"
                      marginTop="14px"
                      marginBottom="14px"
                      borderRadius="5px"
                    >
                      <TextComponent
                        isPreview={preview}
                        getButtonName={setButtonData}
                        getButtonNameValue={setButtonDataValue}
                        title="Call Caption &amp; Number"
                        type="tel"
                        placeholder="+91 6328921458"
                        value="Call Now"
                        placeholderForCall="Call Now"
                      />

                      <TextComponent
                        isPreview={preview}
                        getButtonName={setButtonData}
                        getButtonNameValue={setButtonDataValue}
                        title="Link Caption &amp; Link"
                        type="url"
                        placeholder="https://"
                        value="Visit Now"
                        placeholderForCall="Visit Now"
                      />
                      <TextWithNumberComponent
                        isPreview={preview}
                        getButtonName={setButtonDataWaMe}
                        getButtonNameValue={setButtonDataValueWaMe}
                        getButtonNameValueMsg={setButtonDataValueMsg}
                        title="WA me Caption &amp; Number &amp; Msg"
                        type="tel"
                        typeMsg="text"
                        placeholder="+91 6328921458"
                        placeholderMsg="Msg"
                        value="Whatsapp Me"
                        placeholderForCall="Whatsapp Me"
                      />
                    </Card>
                  </div>

                  <ImageComponent
                    isPreview={preview}
                    listImage={listofImages}
                  />

                  <Grid
                    templateColumns={{ sm: "1fr", sm: "repeat(2, 1fr)" }}
                    gap="22px"
                    className="user-div"
                  >
                    <Card
                      p="16px"
                      marginTop="14px"
                      marginBottom="14px"
                      borderRadius="2px"
                    >
                      <CardBody px="15px">
                        <VideoComponent
                          isPreview={preview}
                          videoBase64={setBase64}
                        />
                      </CardBody>
                      <CardHeader paddingLeft="10px" pt="10px">
                        <Text
                          as="b"
                          color="red"
                          fontSize="15px"
                          fontWeight="400"
                        >
                          *Video file size must be less than 1 MB
                        </Text>
                      </CardHeader>
                    </Card>

                    <Card
                      p="16px"
                      marginBottom="14px"
                      marginTop="14px"
                      borderRadius="2px"
                    >
                      <CardBody px="15px">
                        <PdfComponent
                          isPreview={preview}
                          pdfdata={setPdfData}
                        />
                      </CardBody>
                      <CardHeader paddingLeft="10px" pt="10px">
                        <Text
                          as="b"
                          color="red"
                          fontSize="15px"
                          fontWeight="400"
                        >
                          *PDF file size must be less than 1 MB
                        </Text>
                      </CardHeader>
                    </Card>
                  </Grid>
                </Flex>
              </Box>
            </Grid>
            <Box p="20px" align="center">
              {numberArray.length > 0 && numberValidated ? (
                // (
                //   <PreviewBox
                //     onClose={modal1.onClose}
                //     onOpen={modal1.onOpen}
                //     isOpen={modal1.isOpen}
                //     numberCount={numberArray.length}
                //     image={selecteddata.image}
                //     pdfName={selecteddata.pdfname}
                //     videoName={selecteddata.videoname}
                //     message={selecteddata.message}
                //     handleReviewSubmit={() => {
                //       // console.warn(numberArray);
                //       handleReviewSubmit(numberArray);
                //     }}
                //   ></PreviewBox>
                // ) :
                <PreviewBoxBtn
                  onClose={modal1.onClose}
                  onOpen={modal1.onOpen}
                  isOpen={modal1.isOpen}
                  numberCount={numberArray.length}
                  image={selecteddata.image}
                  btnNameList={wbtName.length != [] ? wbtName : []}
                  btnValueList={btnValue.length != [] ? btnValue : []}
                  // wbtName!=[]?wbtName:null,
                  // btnValue!=[]?btnValue:null,
                  wamecaption={wameCaption.btnCaptionNumber}
                  wamemsg={wameCaption.btnCaptionMsg}
                  wamename={wameCaption.btnCaptionName}
                  pdfName={selecteddata.pdfname}
                  videoName={selecteddata.videoname}
                  message={selecteddata.message}
                  handleReviewSubmit={() => {
                    // console.warn(numberArray);
                    handleReviewSubmit(numberArray);
                  }}
                ></PreviewBoxBtn>
              ) : (
                <Text color="blue">
                  * at least one number is required for Proceed
                </Text>
              )}
              {/* <Button
                  size="lg"
                  colorScheme="teal"
                  variant="solid"
                  pl="100px"
                  pr="100px"
                  onClick={() => {
                    var isValid = validate(stringArray);
                    //console.logisValid);
                    if (isValid === true) {
                      setNumberArray(stringArray);
                      showPreview();
                    } else {
                      alert.show("Please Fill out all Number field Properly", {
                        title: "Required Field",
                      });
                      // startUploading = false;
                    }
                  }}
                >
                  Preview
                </Button> */}
            </Box>
            {/* <Flex>
              <Box p="20px" align="center">
                <Button
                  size="lg"
                  colorScheme="teal"
                  variant="solid"
                  pl="100px"
                  pr="100px"
                  onClick={() => {
                    hidePreview();
                  }} */}
            {/* // } */}
            {/* >
                  Edit
                </Button> */}
            {/* </Box>
              <Box p="20px" align="center">
                <Button
                  size="lg"
                  colorScheme="teal"
                  variant="solid"
                  pl="100px"
                  pr="100px"
                  onClick={() => {
                    var isValid = validate(stringArray);
                    //console.logisValid);
                    if (isValid === true) {
                      // if(messageDataFromChild != ""){
                      // startUploading = true;
                      var username = getJWTData();
                      createCampaign(
                        username["username"],
                        // stringArray,
                        numberArray,
                        "whatsapp_button_balance",
                        imageSelected ? imageDataFromChild : null,
                        pdfSelected ? pdfDataFromChild["name"] : null,
                        pdfSelected ? pdfDataFromChild["base64"] : null,
                        videoSelected ? videoDataFromChild["name"] : null,
                        videoSelected ? videoDataFromChild["base64"] : null,
                        messageDataFromChild,
                        buttonNameaFromChild,
                        buttonDataFromChild,
                        buttonNameFromChildWaMe,
                        buttonDataFromChildWaMe,
                        buttonDataFromChildMsg
                      );
                      // }else{
                      //   alert("Please Fill out message box");
                      //   startUploading = false;
                      // }
                      // //console.logstringArray.length);
                    } else {
                      alert.show("Please Fill out all * mark box", {
                        title: "Required",
                      });
                      // startUploading = false;
                    }
                  }}
                >
                  Upload
                </Button>
              </Box>
            </Flex>
            )}
            {/* <Box p="20px" align="center">
              <Button
                size="lg"
                colorScheme="teal"
                variant="solid"
                pl="100px"
                pr="100px"
                onClick={() => {
                  var isValid = validate(stringArray);
                  //console.logisValid);
                  if (isValid === true) {
                    // if(messageDataFromChild != ""){
                    startUploading = true;
                    var username = getJWTData();
                    createCampaign(
                      username["username"],
                      stringArray,
                      "whatsapp_button_balance",
                      imageSelected ? imageDataFromChild : null,
                      pdfSelected ? pdfDataFromChild["name"] : null,
                      pdfSelected ? pdfDataFromChild["base64"] : null,
                      videoSelected ? videoDataFromChild["name"] : null,
                      videoSelected ? videoDataFromChild["base64"] : null,
                      messageDataFromChild,
                      buttonNameaFromChild,
                      buttonDataFromChild,
                      buttonNameFromChildWaMe,
                      buttonDataFromChildWaMe,
                      buttonDataFromChildMsg
                    );
                    // }else{
                    //   alert("Please Fill out message box");
                    //   startUploading = false;
                    // }
                    // //console.logstringArray.length);
                  } else {
                    alert("Please Fill out all * mark box");
                    startUploading = false;
                  }
                }}
              >
                Upload
              </Button>
              {error && <Alert>{error}</Alert>}
            </Box> */}
          </Flex>
        </Box>
      )}
    </Fragment>
  );
}

export default CampainDashboardButton;
