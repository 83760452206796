import { React, useContext, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  Textarea,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  Spacer,
} from "@chakra-ui/react";
import "../../components/UserDiv/userdiv.css";
// import {GlobalInfo} from '../../views/Dashboard/Campaign_btn_api';
// import {GlobalInfoCamp} from '../../views/Dashboard/Campaign';
function MessageComponent(props) {
  // const {isPreview} =useContext(GlobalInfo);
  // const {isPreview} =useContext(GlobalInfoCamp);
  const ref = useRef(null);
  let [errorMessage, setErrorMessage] = useState("");
  let [value, setValue] = useState("");
  const bgColorForInput = useColorModeValue("#e8f0fe", "#e8f0fe");
  const [msgLength, setMsgLength] = useState(0);
  // const handleClick = (event) => {
  //   // 👇️ access textarea value
  //   //console.logref.current.value);
  // };

  let handleInputChange = (e) => {
    let inputValue = ref.current.value;
    // setErrorMessage(...errorMessage, "Enter a valid Message");
    // setValue(inputValue);
    // //console.log(inputValue.length)
    setMsgLength(inputValue.length);
    props.getMessages(inputValue);
  };
  return (
    <>
      {/* <Spacer></Spacer> */}
      <Box pb="20px" className="user-div" mb="20px">
        <Box
          pt="10px"
          pl="10px"
          ml="-14px"
          mr="-14px"
          mb="14px"
          borderTopRightRadius="10px"
          borderTopLeftRadius="10px"
          borderBottom="2px green solid"
          backgroundColor="lightskyblue"
        >
          <Text color="black" size="lg" as="b" mb="10px">
            Message: {1500 - msgLength} words left
          </Text>
        </Box>
        <Textarea
          // className="placeholder-red-300 border border-red-400"
          // class="placeholder-red-300 border border-red-400"
          borderRadius="0px"
          variant="outline"
          minHeight="290px"
          color="black"
          ref={ref}
          bg="white"
          maxLength={1500}
          readOnly={props.isPreview}
          // isRequired="true"
          errorBorderColor={errorMessage ? "#a9a9a9" : "#a9a9a9"}
          // value={value}
          onChange={handleInputChange}
          isInvalid
          placeholder="Paste Message With Emoji , if any"
          _placeholder={{ opacity: 0.7, color: "inherit" }}
          size="lg"
        />
      </Box>
    </>
  );
}

export default MessageComponent;
