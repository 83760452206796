import { React, createContext, Component, useState, useRef } from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  Stack,
  useColorModeValue,
  propNames,
  Image,
  Box,
} from "@chakra-ui/react";

import { useAlert } from "react-alert";
export const ImageData = createContext([]);


import ImageUploading from "react-images-uploading";

function ImageComponent(props) {
  const [images, setImages] = useState([]);
  const [base64Images, base64setImages] = useState([]);
  const maxNumber = 4;
  let imageDataLocal = [];
  const alert = useAlert();
  // const fileToDataUri = (image) => {
  //   return new Promise((res) => {
  //     const reader = new FileReader();
  //     const { type, name, size } = image;
  //     reader.addEventListener("load", () => {
  //       res({
  //         base64: reader.result,
  //         name: name,
  //         type,
  //         size: size,
  //       });
  //     });
  //     reader.readAsDataURL(image);
  //   });
  // };
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    //console.logimageList, addUpdateIndex);

    setImages(imageList);

    console.log(imageList);
    imageDataLocal = imageList;
    props.listImage(imageList);
  };
  const handleError = (imageList) => {
    // data for submit
    //console.logimageList, addUpdateIndex);

    // setImages(imageList);
    if (imageList.maxNumber === true)
      return alert.error("max 4 images can be selected");
    console.log(imageList);
    // imageDataLocal = imageList;
    // props.listImage(imageList);
  };

  // npm i react-file-base64

  const mystyleUpdate = {
    borderRadius: "4px",
    color: "white",
    backgroundColor: "#01796f",
    fontSize: "12px",
    padding: "8px",
    fontFamily: "Arial",
    marginRight: "-15px",
  };
  const mystyleRemove = {
    marginLeft: "10px",
    borderRadius: "4px",
    color: "white",
    backgroundColor: "tomato",
    fontSize: "12px",
    padding: "8px",
    fontFamily: "Arial",
  };
  return (
    <ImageData.Provider value={{ images }}>
      <div className="user-div">
        <Text
          fontWeight="bold"
          pt="10px"
          borderBottom="2px skyblue solid"
          pl="10px"
          ml="-14px"
          mr="-14px"
          mb="14px"
          borderTopRightRadius="10px"
          borderTopLeftRadius="10px"
          backgroundColor="lightsteelblue"
        >
          Upload Image(s):
        </Text>
        <ImageUploading
          onError={handleError}
          multiple={true}
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          base64="true"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              <Text
                fontSize="lg"
                fontWeight="bold"
                textAlign="center"
                mb="22px"
                mt="10px"
                // as="b"
                color="black"
              >
                Select and Upload Image(s)
              </Text>
              <div className="btn-div">
                <button
                  disabled={props.isPreview}
                  style={isDragging ? { color: "red" } : { color: "blue" }}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <p className="button-drop">Click or Drop Here </p>
                </button>

                {images.length > 0 ? (
                  <button disabled={props.isPreview} onClick={onImageRemoveAll}>
                    <p className="button-delete">Remove All Images</p>
                  </button>
                ) : null}
              </div>

              <Stack direction="row" spacing={4} align="center">
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <Image
                      src={image["data_url"]}
                      alt=""
                      boxSize="150px"
                      objectFit="fill"
                      paddingBottom="5px"
                    />

                    <div className="image-item__btn-wrapper">
                      <button
                        disabled={props.isPreview}
                        onClick={() => onImageUpdate(index)}
                      >
                        <p style={mystyleUpdate}>Update</p>{" "}
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        disabled={props.isPreview}
                        onClick={() => onImageRemove(index)}
                      >
                        <p style={mystyleRemove}>Remove</p>
                      </button>
                    </div>
                  </div>
                ))}
              </Stack>
              <Text
                fontWeight="bold"
                textAlign="center"
                mb="22px"
                mt="22px"
                color="red"
                fontSize="15px"
              >
                * maximum 4 images can be added{" "}
              </Text>
            </div>
          )}
        </ImageUploading>
      </div>
    </ImageData.Provider>
  );
}
export default ImageComponent;
