import React, { useContext, useState, useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  FormLabel,
  Input,
  useColorModeValue,
  Grid,
  useDisclosure,
} from "@chakra-ui/react";
import { useAlert } from "react-alert";
import FileBase64 from "react-file-base64";
import "../../components/UserDiv/userdiv.css";
// import {GlobalInfo} from '../../views/Dashboard/Campaign_btn_api';
function PdfComponent(props) {
  const alert = useAlert();
  // const {isPreview} =useContext(GlobalInfo);
  // const [selected_whatsapp, setGender] = useState("Whatsapp Balance");
  const bgColorForInput = useColorModeValue("#c7ccdb", "#c7ccdb");
  let [setPdf, setPdfBase64] = useState({});
  // let value;
  let ref = useRef();
  const onChange = (event) => {
    // data for submit
    var file = event.target.files[0];
    // //console.logevent.target.files[0]);
    if (
      file === null ||
      file instanceof Blob == false ||
      file.size > 2097152 / 2
    ) {
      alert.show("Please attach Pdf of size less than 1 MB", {
        title: "Size Alert",
      });
      ref.current.value = null;
      return false;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var fileName = file.name;
      var fileBase64 = reader.result;
      var mapData = { name: fileName, base64: fileBase64 };
      props.pdfdata(mapData);
      // props.pdfdata(file);

      // //console.logreader.result);
      // //console.logfileName);
      // alert('uploading data : ' + jsondata)
    };
    reader.onerror = function (error) {
      //console.log"Error: ", error);
    };
  };
  // const getFiles = (files) => {
  //   if (files["type"] === "application/pdf") {
  //     if (files["size"] <= "1048576 kB") {
  //       props.pdfdata(files);
  //     } else {
  //       alert("PDF size must be less than 1 MB");
  //     }
  //     // setPdfBase64(files);
  //     // value = files;
  //   } else {
  //     alert("Please select .pdf format file only ");
  //     // value = {};
  //   }
  //   // //console.log'pdf file :',setPdf);
  // };
  const removeSelected = () => {
    ref.current.value = null;
  };
  const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial",
  };

  return (
    <div style={{ maxWidth: "14rem" }}>
      <Box height="40px">
        <Text
          fontWeight="bold"
          pt="0px"
          pl="5px"
          ml="-14px"
          mr="-14px"
          mb="14px"
          mt="-14px"
          borderBottom="2px green solid"
          backgroundColor="red.300"
        >
          Upload PDF:
        </Text>
      </Box>

      <Text as="b" color="black" fontSize="lg" fontWeight="bold">
        Select the PDF:
      </Text>

      <Flex direction="column" pt={{ md: "25px" }}>
        {/* <FileBase64
          // type="application/pdf"
          multiple={false}
          onDone={getFiles.bind(this)}
        /> */}
        <input
          disabled={props.isPreview}
          className="choose-pdf"
          color="black"
          ref={ref}
          type="file"
          id="pdf"
          // readOnly={props.isPreview}
          onChange={onChange}
          name="input"
          accept="application/pdf"
        />
        <br />
        <Button onClick={removeSelected}>remove pdf</Button>
        <br />
      </Flex>
    </div>
  );
}

export default PdfComponent;
